@use "../../global" as *;

/*=================================
  table
=================================*/
table {
  border-collapse: collapse;
  border-spacing: 0;
  margin: 30px 0 50px;
  width: 100%;
  background: #fff;
  caption {
    font-size: 1.4rem;
    font-weight: 700;
    margin: 20px 0;
    text-align: left;
  }
  thead {
    th,td {
      font-size: 1.6rem;
      font-weight: 700;
      color: #fff;
      text-align: center;
      background: $color-theme;
    }
  }
  th {
    font-weight: 700;
    color: #3a67bb;
    text-align: left;
    background: #ebeef4;
    @include mq(pc) {
      width: 176px;
    }
  }
  td {
    font-weight: 400;
    @include mq(pc) {
      width: calc(100% - 176px);
    }
  }
  tr {
    &:not(:first-child) {
      th,td {
        border-top: 1px solid #e7e7e7;
      }
    }
  }
  th,td {
    font-size: 1.5rem;
    line-height: 2;
    letter-spacing: .06em;
    padding: clamp(13px, vw(18), 18px) clamp(20px, vw(25), 25px);
    vertical-align: top;
  }
  &.scroll {
    white-space: nowrap;
    overflow: auto;
    display: block;
    border: none;
    ::-webkit-scrollbar {
      height: 5px;
    }
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    ::-webkit-scrollbar-thumb {
      background: #bcbcbc;
    }
  }
}
.scrollAnnounce {
  display: none;
}
.wp-block-table {
  table {
    border-collapse: collapse;
    border-spacing: 0;
    margin: 30px 0 50px;
    width: 100%;
    background: #fff;
    caption {
      font-size: 1.4rem;
      font-weight: 700;
      margin: 20px 0;
      text-align: left;
    }
    thead {
      th,td {
        font-size: 1.6rem;
        font-weight: 700;
        color: #fff;
        text-align: center;
        background: $color-theme;
      }
    }
    th {
      font-weight: 700;
      color: #3a67bb;
      text-align: left;
      background: #ebeef4;
      @include mq(pc) {
        width: 176px;
      }
    }
    td {
      font-weight: 400;
      @include mq(pc) {
        width: calc(100% - 176px);
      }
    }
    tr {
      &:not(:first-child) {
        th,td {
          border-top: 1px solid #e7e7e7;
        }
      }
    }
    th,td {
      font-size: 1.5rem;
      line-height: 2;
      letter-spacing: .06em;
      padding: clamp(13px, vw(18), 18px) clamp(20px, vw(25), 25px);
      vertical-align: top;
    }
    &.scroll {
      white-space: nowrap;
      overflow: auto;
      display: block;
      border: none;
      ::-webkit-scrollbar {
        height: 5px;
      }
      ::-webkit-scrollbar-track {
        background: #f1f1f1;
      }
      ::-webkit-scrollbar-thumb {
        background: #bcbcbc;
      }
    }
  }
}
.wp-block-table td, .wp-block-table th {
  border: initial;
}
@media screen and (max-width: $display-width-s){
  /*=================================
    table
  =================================*/
  table {
    margin: 20px 0 40px;
    &:not(.noscroll) {
      display: block;
      white-space: nowrap;
      overflow-x: auto;
      border: none;
    }
    caption {
      font-size: 1.5rem;
      margin: 15px 0;
    }
    th,td {
      padding: 14px 15px;
    }
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }
    &::-webkit-scrollbar-track {
      border-radius: none;
      background: #d6d6d6;
    }
    /* スクロールのつまみ部分の設定 */
    &::-webkit-scrollbar-thumb {
      background: $color-theme;
    }
  }
  .wp-block-table {
    table {
      margin: 20px 0 40px;
      &:not(.noscroll) {
        display: block;
        white-space: nowrap;
        overflow-x: auto;
        border: none;
      }
      caption {
        font-size: 1.5rem;
        margin: 15px 0;
      }
      th,td {
        padding: 14px 15px;
      }
      &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
      }
      &::-webkit-scrollbar-track {
        border-radius: none;
        background: #d6d6d6;
      }
      /* スクロールのつまみ部分の設定 */
      &::-webkit-scrollbar-thumb {
        background: $color-theme;
      }
    }
  }
  .scrollAnnounce {
    display: block;
    text-align: center;
    .text {
      font-size: 1.3rem;
      font-weight: 600;
      color: #fff;
      background: $color-theme;
      padding: 3px 6px;
      position: relative;
      &::after {
        content: "";
        border-style: solid;
        border-width: 7px 6.5px 0 6.5px;
        border-color: $color-theme transparent transparent transparent;
        display: inline-block;
        position: absolute;
        bottom: -7px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}