@use "../../global" as *;

/*=================================
  accordion
=================================*/
.js-accordion__btn {
  position: relative;
  cursor: pointer;
}
.js-accordion__item {
  display: none;
}


@include mq(sp) {
  /*=================================
    accordion
  =================================*/
}