@use "../../global" as *;

/*=================================
  breadcrumb
=================================*/
.breadcrumb {
  margin: 20px auto;
  padding: 0 3%;
  ul {
    li {
      font-size: 1.4rem;
      line-height: 1;
      color: #B8B8B8;
      display: inline-block;
      a {
        font-size: 1.4rem;
        line-height: 1;
        color: $font-color-base;
        text-decoration: none;
        margin-right: 18px;
        @include mq(pc) {
          &:hover {
            color: $color-link;
            text-decoration: underline;
          }
        }
        &::after {
          content: "";
          width: 6px;
          height: 10px;
          background: url(../img/icon_arrow_breadcrumb.png) no-repeat center center;
          background-size: contain;
          display: inline-block;
          margin-left: 18px;
        }
      }
    }
  }
}
@include mq(sp) {
  /*=================================
    breadcrumb
  =================================*/
  .breadcrumb {
    ul {
      li {
        font-size: 1rem;
        line-height: 1.5;
        a {
          font-size: 1rem;
          line-height: 1.5;
          margin-right: 7px;
          &::after {
            margin-left: 7px;
            margin-bottom: -1px;
          }
        }
      }
    }
  }
}