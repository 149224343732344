@use "../../global" as *;

/*=================================
  title
=================================*/
.ttl--base {
  font-family: "Montserrat", "Noto Sans JP", "源ノ角ゴシック","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
  font-size: clamp(4rem, vw(60), 6rem);
  font-weight: 600;
  color: $font-color-base;
  line-height: 1.25;
  letter-spacing: .12em;
  text-align: center;
  margin-bottom: clamp(44px, vw(66), 66px);
  padding-top: clamp(34px, vw(51), 51px);
  position: relative;
  &::before {
    content: "";
    border-style: solid;
    border-width: 0 clamp(11px, vw(16), 16px) clamp(18px, vw(27), 27px) clamp(11px, vw(16), 16px);
    border-color: transparent transparent $color-yellow transparent;
    display: inline-block;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}
.ttl--page {
  padding: 0 3%;
  margin-bottom: 30px;
  &__inner {
    max-width: $base-width-in;
    margin: 0 auto;
  }
  .ttl {
    font-family: "Montserrat", "Noto Sans JP", "源ノ角ゴシック","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
    font-size: clamp(3.5rem, vw(45), 4.5rem);
    font-weight: 700;
    line-height: 1.33333;
    padding-left: clamp(25px, vw(45), 45px);
    position: relative;
    &::before {
      content: "";
      border-style: solid;
      border-width: 0 clamp(8px, vw(13), 13px) clamp(15px, vw(24), 24px) clamp(8px, vw(13), 13px);
      border-color: transparent transparent $color-yellow transparent;
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }
  .text {
    font-size: 1.6rem;
    font-weight: 400;
    margin-top: 7px;
  }
}

@media screen and (max-width: $display-width-s) {
  /*=================================
    title
  =================================*/
  .ttl--base {
    font-size: 2rem;
    line-height: 1.55;
    margin-bottom: 20px;
    & + .ttl--text {
      margin-top: -10px;
      margin-bottom: 20px;
    }
  }
  .ttl--page {
    padding: 0 per(15);
    margin-bottom: 30px;
    .ttl {
      font-size: 2.4rem;
      padding-left: 20px;
      &::before {
        border-width: 0 6px 13px 6px;
      }
    }
    .text {
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 1.714285;
    }
  }
}