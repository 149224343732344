@use "../../global" as *;
/*=================================
  topPage
=================================*/
.home {
  .vision {
    padding: clamp(55px, vw(110), 110px) 0 0;
    background: #fff;
    &Cont {
      background: url(../img/vision_bg.png) no-repeat right top $color-theme;
      background-size: clamp(322px, vw(522), 522px) auto;
      position: relative;
      margin-top: clamp(89px, vw(134), 134px);
      padding: 1% 8%;
      &__inner {
        max-width: $base-width-in;
        margin: 0 auto;
        display: flex;
        align-items: center;
        .vision__img {
          max-width: clamp(260px, vw(560), 560px);
          width: 50%;
          transform: translate(-14.2857%, -13.25%);
          align-self: flex-start;
        }
        .vision__text {
          font-size: clamp(1.4rem, vw(16), 1.6rem);
          font-weight: 400;
          line-height: 2.25;
          letter-spacing: .12em;
          color: #fff;
          p + p {
            margin-top: 1rem;
          }
        }
      }
    }
  }
  .media {
    padding: clamp(52px, vw(105), 105px) 0 0;
    .mediaList {
      display: flex;
      justify-content: center;
      background: $color-theme-light;
      .sliderWrap {
        width: calc(100% / 3);
        height: initial;
      }
      &__item {
        background: $color-theme-light;
        text-align: center;
        padding: clamp(35px, vw(70), 70px) 5% clamp(54px, vw(108), 108px);
        height: 100%;
        .media__img {
          margin: 0 auto clamp(25px, vw(50), 50px);
          display: block;
        }
        .media__label {
          @include font-montserrat;
          font-size: clamp(1.3rem, vw(16), 1.6rem);
          font-weight: 700;
          letter-spacing: .16em;
          color: $color-theme;
        }
        .media__ttl {
          font-size: clamp(1.6rem, vw(23), 2.3rem);
          font-weight: 700;
          letter-spacing: .06em;
          margin: clamp(9px, vw(18), 18px) 0 0;
        }
        .media__text {
          font-size: clamp(1.2rem, vw(14), 1.4rem);
          font-weight: 400;
          letter-spacing: .12em;
          margin: clamp(11px, vw(23), 23px) 0 0;
        }
        .media__btn {
          @include font-montserrat;
          font-size: clamp(1.2rem, vw(13), 1.3rem);
          font-weight: 700;
          letter-spacing: .16em;
          color: #fff;
          text-decoration: none;
          background: $color-theme;
          text-align: center;
          border-radius: 50px;
          box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
          display: block;
          width: 88%;
          margin: clamp(16px, vw(32), 32px) auto 0;
          padding: clamp(7px, vw(14), 14px) 10px;
          position: relative;
          overflow: hidden;
          &::after {
            content: "";
            width: 100%;
            height: 100%;
            display: block;
            background: $color-yellow;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
            /*アニメーション*/
            transition: transform .6s cubic-bezier(0.8, 0, 0.2, 1) 0s;
            transform: scale(0, 1);
            transform-origin: right top;
          }
          @include mq(pc) {
            &:hover {
              box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.2);
              &::after {
                transform: scale(1, 1);
                transform-origin: left top;
              }
              span {
                &::after {
                  transform: translateX(10px);
                }
              }
            }
          }
          span {
            position: relative;
            z-index: 2;
            &::after {
              content: "";
              width: 15px;
              height: 8px;
              display: inline-block;
              background: url(../img/arrow_white_pc.png) no-repeat center center;
              background-size: contain;
              margin: 0 0 .15rem 1rem;
              @include animation-base;
            }
          }
        }
        .media__btn--sub {
          font-family: "Montserrat", "Noto Sans JP", "源ノ角ゴシック","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
          font-size: clamp(1.2rem, vw(13), 1.3rem);
          font-weight: 700;
          letter-spacing: .16em;
          color: #fff;
          text-decoration: none;
          background: $color-pink;
          text-align: center;
          border-radius: 50px;
          box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
          display: block;
          width: 88%;
          margin: clamp(16px, vw(32), 32px) auto 0;
          padding: clamp(7px, vw(14), 14px) 10px;
          position: relative;
          overflow: hidden;
          &::after {
            content: "";
            width: 100%;
            height: 100%;
            display: block;
            background: $color-yellow;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
            /*アニメーション*/
            transition: transform .6s cubic-bezier(0.8, 0, 0.2, 1) 0s;
            transform: scale(0, 1);
            transform-origin: right top;
          }
          @include mq(pc) {
            &:hover {
              box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.2);
              &::after {
                transform: scale(1, 1);
                transform-origin: left top;
              }
              span {
                &::after {
                  transform: translateX(10px);
                }
              }
            }
          }
          span {
            position: relative;
            z-index: 2;
            &::after {
              content: "";
              width: 15px;
              height: 8px;
              display: inline-block;
              background: url(../img/arrow_white_pc.png) no-repeat center center;
              background-size: contain;
              margin: 0 0 .15rem 1rem;
              @include animation-base;
            }
          }
        }
      }
    }
    /* slider設定 */
    .slider--media {
      &.slick-slider {
        padding: 0 46px;
        .slick-track {
          display: flex;
        }
      }
      &:not(.slick-slider) {
        .sliderWrap {
          &:nth-child(2n) {
            .mediaList__item {
              background: #f0f4fb;
            }
          }
        }
      }
      .slick-current {
        .mediaList__item {
          background: #f0f4fb;
        }
      }
      /* Arrows */
      .slick-prev,
      .slick-next {
        top: 40%;
        width: initial;
        height: initial;
        transform: translate(0, 0);
        z-index: 1;
      }
      .slick-prev:before,
      .slick-next:before {
        content: "";
        width: 23px;
        height: 12px;
        display: inline-block;
        opacity: 1;
        background: url(../img/arrow_blue_pc.png) no-repeat center center;
        background-size: contain;
      }
      .slick-prev {
        left: 1.081%;
      }
      [dir='rtl'] .slick-prev {
        right: 1.081%;
        left: auto;
      }
      [dir='rtl'] .slick-prev:before {
        content: '';
      }
      .slick-next {
        right: 1.081%;
        transform: rotateY(180deg);
      }
      [dir='rtl'] .slick-next {
        right: auto;
        left: 1.081%;
      }
      [dir='rtl'] .slick-next:before {
        content: '';
      }
      /* Dots */
      &.slick-dotted {
        margin-bottom: 0!important;
      }
      .slick-dots {
        bottom: clamp(15px,vw(35),35px);
      }
      .slick-dots li {
        width: clamp(10px,vw(13),13px);
        height: clamp(10px,vw(13),13px);
        margin: 0 clamp(4px,vw(9),9px);
      }
      .slick-dots li button {
        width: clamp(10px,vw(13),13px);
        height: clamp(10px,vw(13),13px);
        padding: 0;
      }
      .slick-dots li button:before {
        content: "";
        width: clamp(10px,vw(13),13px);
        height: clamp(10px,vw(13),13px);
        display: inline-block;
        border-radius: 50px;
        background: #d6d9e0;
        opacity: 1;
        color: #d6d9e0;
      }
      .slick-dots li.slick-active button:before {
        opacity: 1;
        color: #688dd1;
        background: #688dd1;
      }
    }
  }
  .company {
    padding: clamp(55px, vw(110), 110px) 3%;
    background: url(../img/company_bg.jpg) repeat left top;
    &__inner {
      max-width: $base-width-in;
      margin: 0 auto;
    }
    &__table {
      max-width: 900px;
      margin: 0 auto;
    }
  }
  .access {
    padding: clamp(55px, vw(110), 110px) 3%;
    background: url(../img/access_bg.jpg) no-repeat center center;
    background-size: cover;
    &__inner {
      max-width: $base-width-in;
      margin: 0 auto;
    }
    &__gMap {
      width: 100%;
      padding-top: 48.645%;
      border: 3px solid #cbd5e8;
      overflow: hidden;
      position: relative;
      iframe {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
      }
    }
  }
}
/* メインビジュアル */
.kv {
  background: url(../img/kv_bg_pc.jpg) no-repeat center top;
  background-size: cover;
  padding: 0 3%;
  height: clamp(56vh, calc(100vw / 2), 86vh);
  position: relative;
  &__catch {
    font-size: clamp(4.6rem, vw(66), 6.6rem);
    font-weight: 300;
    font-feature-settings: "palt";
    line-height: 1.272727;
    letter-spacing: .12em;
    color: #fff;
    text-shadow: 0px 0px 14px rgba(0, 0, 0, 0.5);
    position: absolute;
    left: 3%;
    bottom: 6%;
  }
}

@media screen and (max-width: $display-width-s) {
  /*=================================
    topPage
  =================================*/
  .home {
    .vision {
      padding: 50px 0 0;
      &Cont {
        background: url(../img/vision_bg.png) no-repeat left top $color-theme;
        background-size: 85% auto;
        margin-top: 30px;
        padding: per(30) per(15) 0;
        &__inner {
          flex-wrap: wrap;
          flex-direction: column-reverse;
          .vision__img {
            max-width: initial;
            width: 80%;
            height: 150px;
            transform: translate(per(20), 15px);
            align-self: flex-end;
            object-fit: cover;
            object-position: center;
          }
          .vision__text {
            font-size: 1.5rem;
          }
        }
      }
    }
    .media {
      padding: 50px 0 0;
      .mediaList {
        .sliderWrap {
          width: 100%;
        }
        &__item {
          padding: 35px per(15) 50px;
          .media__img {
            max-width: 80%;
            margin: 0 auto 25px;
          }
          .media__label {
            font-size: 1.5rem;
          }
          .media__ttl {
            font-size: 1.6rem;
            margin: 10px 0 0;
          }
          .media__text {
            font-size: 1.4rem;
            margin: 10px 0 0;
          }
          .media__btn {
            font-size: 1.2rem;
            margin: 16px auto 0;
            padding: 10px per(15);
            &::after {
              content: none;
            }
          }
          .media__btn--sub {
            font-size: 1.2rem;
            margin: 16px auto 0;
            padding: 10px per(15);
            &::after {
              content: none;
            }
          }
        }
      }
      /* slider設定 */
      .slider--media {
        &.slick-slider {
          padding: 0 per(40);
        }
        /* Arrows */
        .slick-prev,
        .slick-next {
          top: 50%;
        }
        .slick-prev:before,
        .slick-next:before {
          width: 20px;
          height: 9px;
          background: url(../img/arrow_blue_sp.png) no-repeat center center;
          background-size: contain;
        }
        .slick-prev {
          left: per(10);
        }
        [dir='rtl'] .slick-prev {
          right: per(10);
        }
        .slick-next {
          right: per(10);
        }
        [dir='rtl'] .slick-next {
          left: per(10);
        }
        /* Dots */
        .slick-dots {
          bottom: 15px;
        }
        .slick-dots li {
          width: 10px;
          height: 10px;
          margin: 0 8px;
        }
        .slick-dots li button {
          width: 10px;
          height: 10px;
        }
        .slick-dots li button:before {
          width: 10px;
          height: 10px;
        }
      }
    }
    .company {
      padding: 50px per(15);
      &__table {
        max-width: initial;
        th {
          width: 12rem;
        }
      }
    }
    .access {
      padding: 50px per(15);
      &__gMap {
        border: 2px solid #cbd5e8;
      }
    }
  }
  /* メインビジュアル */
  .kv {
    background: url(../img/kv_bg_pc.jpg) no-repeat right 20% center;
    background-size: cover;
    padding: 0 per(15);
    height: 70vh;
    &__catch {
      font-size: 4.2rem;
      left: per(15);
    }
  }
}