@use "../../global" as *;
/*=================================
  page--article
=================================*/
/* 記事一覧 */
.articleList {
  position: relative;
  z-index: 1;
  &__item {
    background: $color-gray;
    border-radius: 15px;
    max-width: 1000px;
    @include mq(pc) {
      &:hover {
        .textWrap__ttl {
          text-decoration: underline!important;
        }
      }
    }
    .itemWrap {
      padding: 40px 40px 40px 50px;
      display: flex;
      justify-content: flex-end;
      flex-direction: row-reverse;
      flex-wrap: wrap;
      .thumb {
        width: 38%;
        max-width: 350px;
        margin-left: 2.8%;
        border-radius: 5px;
        overflow: hidden;
        img {
          margin: 0 auto;
        }
        & + .textWrap {
          width: calc(100% - (38% + 2.8%));
        }
      }
      .textWrap {
        width: 100%;
        &__date {
          font-size: 1.2rem;
          font-weight: 400;
          line-height: 1;
          margin-bottom: 14px;
        }
        &__ttl {
          font-size: 2.4rem;
          font-weight: 500;
          line-height: 1.66666;
          display: block;
          text-decoration: none;
          position: relative;
          &::before {
            content: "";
            width: 8px;
            height: 8px;
            display: inline-block;
            background: $color-theme;
            border-radius: 50px;
            position: absolute;
            top: 16px;
            left: -20px;
          }
        }
        &__text {
          font-size: 1.6rem;
          font-weight: 400;
          line-height: 2;
          margin-top: 20px;
        }
      }
    }
    & + .articleList__item {
      margin-top: 40px;
    }
  }
}
.articleListWrap {
  padding: 100px 3%;
  margin-top: 50px;
  background: $color-gray;
  &__in {
    padding: 70px 70px 40px;
    background: #fff;
    border-radius: 20px;
    max-width: $base-width-in;
    margin: 0 auto;
    @include singleStyle-base;
    @for $i from 1 through 6 {
      & > h#{$i}:first-child {
        margin-top: 0!important;
      }
    }
    @include mq(tab) {
      padding: 70px vw(70) 40px;
    }
    .pager {
      @for $i from 1 through 6 {
        & + h#{$i}{
          margin-top: 100px;
        }
      }
    }
  }
}

/* singlePage */
.singlePage {
  padding: 100px 3%;
  margin-top: 50px;
  background: $color-gray;
  &__in {
    padding: 70px 70px 40px;
    background: #fff;
    border-radius: 20px;
    max-width: $base-width-in;
    margin: 0 auto;
    @include singleStyle-base;
    @for $i from 1 through 6 {
      & > h#{$i}:first-child {
        margin-top: 0!important;
      }
    }
    @include mq(tab) {
      padding: 70px vw(70) 40px;
    }
  }
  @include singleStyle-parts;
}

/* 記事詳細ページ */
.articleDetail {
  padding: 50px 3%;
  background: $color-gray;
  @include singleStyle-parts;
  &__in {
    padding: 70px 70px 40px;
    background: #fff;
    border-radius: 20px;
    max-width: $base-width-in;
    margin: 0 auto;
    @for $i from 1 through 6 {
      & > h#{$i}:first-child {
        margin-top: 0!important;
      }
    }
    .tocBox {
      & + * {
        margin-top: 0!important;
      }
    }
  }
  .articleCont {
    @include singleStyle-base;
  }
}

/* 関連記事 */
.relatedBox {
  padding: 100px 1% 50px;
  &__inner {
    max-width: $base-width-in;
    margin: 0 auto;
  }
  &__ttl {
    font-size: 4.5rem;
    font-weight: 700;
    line-height: 1.5;
    &::before {
      content: "";
      width: 12px;
      height: 12px;
      display: inline-block;
      vertical-align: middle;
      border-radius: 50px;
      background: $color-theme;
      margin: -5px 15px 0 0;
    }
  }
  &__text {
    font-size: 1.6rem;
    font-weight: 400;
    margin-top: 7px;
  }
  .relatedList {
    margin-top: 30px;
  }
}
.relatedList {
  display: flex;
  flex-wrap: wrap;
  &__item {
    width: calc((100% - 100px) / 3);
    margin-bottom: 50px;
    &:not(:nth-child(3n + 1)) {
      margin-left: 50px;
    }
    @include mq(pc) {
      &:hover {
        .textWrap__ttl {
          text-decoration: underline!important;
        }
      }
    }
    @include mq(tab) {
      width: calc((100% - vw(100)) / 3);
      margin-bottom: vw(50);
      &:not(:nth-child(3n + 1)) {
        margin-left: vw(50);
      }
    }
    .itemWrap {
      display: flex;
      justify-content: flex-end;
      flex-direction: row-reverse;
      flex-wrap: wrap;
      .thumb {
        width: 100%;
        margin-bottom: 10px;
        border-radius: 10px;
        overflow: hidden;
        position: relative;
        img {
          margin: 0 auto;
        }
        .label {
          font-size: 1.2rem;
          font-weight: 400;
          color: #fff;
          display: inline-block;
          background: rgba(34, 34, 34, .6);
          padding: 7px 16px;
          position: absolute;
          bottom: 0;
          left: 0;
        }
      }
      .textWrap {
        width: 100%;
        &__date {
          font-size: 1.2rem;
          font-weight: 400;
          line-height: 1;
          margin-bottom: 14px;
        }
        &__ttl {
          font-size: 1.6rem;
          font-weight: 500;
          line-height: 1.625;
          display: block;
          text-decoration: none;
        }
        &__text {
          font-size: 1.4rem;
          font-weight: 400;
          line-height: 2;
          margin-top: 10px;
        }
      }
    }
  }
}

@media screen and (max-width: $display-width-s) {
  /* singlePage */
  .singlePage {
    padding: 50px per(15);
    margin-top: 20px;
    &__in {
      padding: 30px per(15) 42px;
      overflow: hidden;
    }
  }
  /* 記事詳細ページ */
  .articleDetail {
    padding: 50px per(15);
    margin-top: 20px;
    &__in {
      padding: 30px per(15) 42px;
      overflow: hidden;
    }
  }
  /* 関連記事 */
  .relatedBox {
    padding: 40px per(40);
    &__ttl {
      font-size: 2.4rem;
      line-height: 1.083333;
      &::before {
         width: 6px;
        height: 6px;
        margin: -5px 8px 0 0;
      }
    }
    &__text {
      font-size: 1.4rem;
      margin-top: 10px;
    }
  }
  .relatedList {
    display: block;
    &__item {
      width: 100%;
      margin-bottom: 30px;
      &:not(:nth-child(3n + 1)) {
        margin-left: 0;
      }
      .itemWrap {
        display: block;
        .thumb {
          margin-bottom: 12px;
          border-radius: 5px;
          .label {
            font-size: 1rem;
          }
        }
        .textWrap {
          &__date {
            margin-bottom: 12px;
          }
          &__ttl {
            font-size: 1.4rem;
            line-height: 1.71428;
          }
          &__text {
            line-height: 1.66666;
            display: none;
          }
        }
      }
    }
  }
  /* 記事一覧ページ */
  .articleList {
    &__item {
      border-radius: 10px;
      .itemWrap {
        padding: 20px per(17) 20px per(27);
        display: block;
        .thumb {
          width: 100%;
          max-width: initial;
          margin: 0 0 15px;
          text-align: center;
          & + .textWrap {
            width: 100%;
          }
        }
        .textWrap {
          &__ttl {
            font-size: 1.6rem;
            &::before {
              width: 6px;
              height: 6px;
              top: 12px;
              left: per(-17);
            }
          }
          &__text {
            font-size: 1.4rem;
            line-height: 1.66666;
            margin-top: 10px;
            display: none;
          }
        }
      }
      & + .articleList__item {
        margin-top: 20px;
      }
    }
  }
  .articleListWrap {
    padding: 50px per(15);
    margin-top: 20px;
    &__in {
      padding: 30px per(15) 42px;
      overflow: hidden;
      .pager {
        @for $i from 1 through 6 {
          & + h#{$i}{
            margin-top: 50px;
          }
        }
      }
    }
  }
}