@use "../global" as *;
/*=================================
footer
=================================*/
.footer {
  background: #7d9dd7;
  &__inner {
    max-width: $base-width;
    margin: 0 auto;
  }
  .fNavList {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 22px 3%;
    &__item {
      display: inline-block;
      position: relative;
      &:not(:first-child) {
        margin: 0 0 0 56px;
        @include mq(tab) {
          margin: 0 0 0 vw(56);
        }
      }
      &::after {
        content: "";
        width: 100%;
        height: 100%;
        display: block;
        background: $color-theme;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        /*アニメーション*/
        transition: transform .3s;
        transform: scale(0, 1);
        transform-origin: right top;
      }
      @include mq(pc) {
        &:hover {
          &::after {
            transform: scale(1, 1);
            transform-origin: left top;
          }
        }
      }
      a {
        font-size: clamp(1.1rem, vw(13), 1.3rem);
        font-weight: 500;
        color: #fff;
        padding: 0 5px;
        display: block;
        text-decoration: none;
        position: relative;
        z-index: 2;
      }
    }
  }
  .copy {
    @include font-montserrat;
    font-size: 1.1rem;
    letter-spacing: .16em;
    color: #fff;
    background: $color-theme;
    padding: 9px 3%;
    display: block;
    text-align: center;
  }
}

@include mq(sp) {
  /*=================================
  footer
  =================================*/
  .footer {
    .fNavList {
      padding: 15px 0 10px;
      &__item {
        margin: 0 per(15) 5px;
        &:not(:first-child) {
          margin: 0 per(15) 5px;
        }
        &::after {
          content: none;
        }
        a {
          font-size: 1.2rem;
          padding: 0;
        }
      }
    }
    .copy {
      font-size: 1rem;
      padding: 10px per(15);
    }
  }
}