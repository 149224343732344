@charset "UTF-8";


@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes hide {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes vibrate {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(15deg);
  }
  50% {
    transform: rotate(-15deg);
  }
  75% {
    transform: rotate(15deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes vibrate02 {
  0% {
    transform: rotate(0deg) scale(1.2);
  }
  5% {
    transform: rotate(15deg) scale(1.2);
  }
  10% {
    transform: rotate(-15deg) scale(1.2);
  }
  15% {
    transform: rotate(15deg) scale(1.2);
  }
  20% {
    transform: rotate(0deg) scale(1);
  }
  100% {
    transform: rotate(0deg) scale(1);
  }
}
@keyframes burger-show {
  0% {
    right: -50%;
  }
  100% {
    right: 0%;
  }
}
@keyframes header-show {
  0% {
    opacity: 0;
    top: -100px;
  }
  100% {
    opacity: 1;
    top: 0;
  }
}
a:hover {
  outline: none;
}
a:active {
  outline: none;
}
a:focus {
  outline: none;
}

@media screen and (min-width: 767px) {
  /* =================================
    hover
  ================================= */
  a {
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: ease-out;
  }
  .widelink {
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: ease-out;
    cursor: pointer;
  }
  .widelink .widelink__cover {
    position: relative;
    overflow: hidden;
  }
  .widelink .widelink__cover::before {
    content: "詳しく見る";
    font-size: 1.4rem;
    color: #fff;
    letter-spacing: 0.06em;
    line-height: 1;
    text-align: center;
    opacity: 0;
    padding: 10px 0;
    width: 55%;
    border: 1px solid #fff;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 6;
    transition-property: opacity, top;
    transition-duration: 0.15s;
    transition-timing-function: ease-out;
  }
  .widelink .widelink__cover::after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 5;
    opacity: 0;
    background-color: rgba(31, 31, 31, 0.5);
    color: white;
    transition-property: opacity;
    transition-duration: 0.15s;
    transition-timing-function: ease-out;
  }
  .widelink .widelink__menu {
    position: relative;
    overflow: hidden;
    display: block;
  }
  .widelink .widelink__menu::before {
    content: "詳しく見る";
    font-size: 1.3rem;
    font-weight: 700;
    color: #fff;
    line-height: 1;
    text-align: center;
    opacity: 0;
    padding: 15px 9%;
    border: 1px solid #fff;
    border-radius: 50px;
    position: absolute;
    bottom: 12%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 6;
    transition-property: opacity, bottom;
    transition-duration: 0.15s;
    transition-timing-function: ease-out;
  }
  .widelink .widelink__article {
    position: relative;
    overflow: hidden;
  }
  .widelink .widelink__article::before {
    content: "";
    display: inline-block;
    width: 130px;
    height: 24px;
    background: url(../img/link_more.png) no-repeat center center;
    opacity: 0;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 6;
    transition-property: opacity, top;
    transition-duration: 0.15s;
    transition-timing-function: ease-out;
  }
  .widelink .widelink__article::after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 5;
    opacity: 0;
    background-color: rgba(0, 84, 142, 0.7);
    color: white;
    transition-property: opacity;
    transition-duration: 0.15s;
    transition-timing-function: ease-out;
  }
  .widelink:hover a {
    text-decoration: none;
  }
  .widelink:hover .widelink__cover::before, .widelink:hover .widelink__article::before {
    opacity: 1;
    top: 50%;
  }
  .widelink:hover .widelink__cover::after, .widelink:hover .widelink__article::after {
    opacity: 1;
  }
  .widelink:hover .widelink__menu::before {
    opacity: 1;
    bottom: 10%;
  }
  .hoverBg .widelink__cover::before {
    content: none;
  }
  .hoverBg .widelink__cover::after {
    content: none;
  }
  .hoverBg:hover {
    background: #e7edf8;
  }
}
/*=================================
  button
=================================*/
.btnWrap {
  margin: 20px auto 30px;
  text-align: center;
}
.btnWrap.center {
  text-align: center;
}
.btnWrap.right {
  text-align: right;
}
.btnWrap > * + * {
  margin-top: 9px;
}

.btn--blue {
  font-size: 1.4rem;
  font-weight: 500;
  color: #fff;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  background: #4673c6;
  padding: 15px;
  position: relative;
  overflow: hidden;
}
.btn--blue span {
  position: relative;
  z-index: 1;
}

.btn--gray {
  font-size: 1.9rem;
  font-weight: 700;
  line-height: 1.05;
  color: #fff;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  background: #333333;
  box-shadow: 0 5px 0 #202427;
  display: block;
  padding: 28px 30px;
  position: relative;
  transition-property: all;
  transition-duration: 0s;
  transition-timing-function: ease-out;
}
@media screen and (min-width: 767px) and (max-width: 1480px) {
  .btn--gray {
    padding: 2.2297297297vw 15.2027027027vw;
  }
}
.btn--gray::after {
  content: "";
  width: 12px;
  height: 19px;
  display: inline-block;
  vertical-align: middle;
  background: url(../img/icon/icon_arrow_w.png) no-repeat center center;
  background-size: contain;
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
}

.btn--blueShadow {
  font-size: 1.4rem;
  font-weight: 500;
  color: #fff;
  display: inline-block;
  text-align: center;
  background: #3da7f0;
  box-shadow: 0 3px 0 #4673c6;
  border-radius: 4px;
  padding: 23px 22px;
  text-decoration: none;
}

.btn--ghost {
  font-size: 1.5rem;
  font-weight: 500;
  color: #fff;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  padding: 15px 58px;
  border: 1px solid #fff;
  background: rgba(255, 255, 255, 0.3);
  position: relative;
}
.btn--ghost::after {
  content: "";
  width: 9px;
  height: 15px;
  display: inline-block;
  vertical-align: middle;
  background: url(../img/icon/icon_arrow_w.png) no-repeat center center;
  background-size: contain;
  position: absolute;
  top: 50%;
  right: 24px;
  transform: translateY(-50%);
}

.btn--border {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1;
  color: #4673c6;
  border: 3px solid #4673c6;
  background: #fff;
  text-decoration: none;
  text-align: center;
  display: block;
  margin: 0 auto;
  padding: 16px 1%;
}
.btn--border::before {
  content: "";
  display: inline-block;
  width: 17px;
  height: 17px;
  background: url(../img/btn_arrow.png) no-repeat center center;
  background-size: contain;
  vertical-align: middle;
}

@media screen and (min-width: 767px) {
  /*=================================
    button
  =================================*/
  .btnWrap {
    display: flex;
    justify-content: center;
  }
  .btnWrap > * + * {
    margin: 0 20px;
  }
  .btn--blue {
    font-size: 2.2rem;
    padding: 23px 37px;
    position: relative;
    overflow: hidden;
  }
  .btn--blue::after {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    background: #ffce08;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    /*アニメーション*/
    transition: transform 0.6s cubic-bezier(0.8, 0, 0.2, 1) 0s;
    transform: scale(0, 1);
    transform-origin: right top;
  }
  .btn--blue:hover::after {
    transform: scale(1, 1);
    transform-origin: left top;
  }
  .btn--gray {
    font-size: 2rem;
    padding: 33px 225px;
  }
  .btn--gray::after {
    width: 14px;
    height: 24px;
    right: 16px;
  }
  .btn--gray:hover {
    box-shadow: none;
    transform: translateY(5px);
  }
  .btn--blueShadow {
    font-size: 2.2rem;
    box-shadow: 0 5px 0 #4673c6;
    padding: 24px 30px;
    transition: none;
  }
  .btn--blueShadow:hover {
    transform: translateY(5px);
    box-shadow: none;
  }
  .btn--ghost {
    font-weight: 700;
    background: transparent;
  }
  .btn--ghost:hover {
    background: rgba(255, 255, 255, 0.2);
  }
  .btn--border {
    font-size: 2.2rem;
    border-width: 4px;
    padding: 20px 66px;
    margin: 0;
    display: inline-block;
  }
  .btn--border::before {
    width: 24px;
    height: 24px;
    margin: 0 6px 3px 0;
  }
  .btn--border:hover {
    background: #f0f8fe;
  }
}
/*=================================
  title
=================================*/
.ttl--base {
  font-family: "Montserrat", "Noto Sans JP", "源ノ角ゴシック", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", sans-serif;
  font-size: clamp(4rem, 4.0540540541vw, 6rem);
  font-weight: 600;
  color: #1a1a1a;
  line-height: 1.25;
  letter-spacing: 0.12em;
  text-align: center;
  margin-bottom: clamp(44px, 4.4594594595vw, 66px);
  padding-top: clamp(34px, 3.4459459459vw, 51px);
  position: relative;
}
.ttl--base::before {
  content: "";
  border-style: solid;
  border-width: 0 clamp(11px, 1.0810810811vw, 16px) clamp(18px, 1.8243243243vw, 27px) clamp(11px, 1.0810810811vw, 16px);
  border-color: transparent transparent #ffce08 transparent;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.ttl--page {
  padding: 0 3%;
  margin-bottom: 30px;
}
.ttl--page__inner {
  max-width: 1180px;
  margin: 0 auto;
}
.ttl--page .ttl {
  font-family: "Montserrat", "Noto Sans JP", "源ノ角ゴシック", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", sans-serif;
  font-size: clamp(3.5rem, 3.0405405405vw, 4.5rem);
  font-weight: 700;
  line-height: 1.33333;
  padding-left: clamp(25px, 3.0405405405vw, 45px);
  position: relative;
}
.ttl--page .ttl::before {
  content: "";
  border-style: solid;
  border-width: 0 clamp(8px, 0.8783783784vw, 13px) clamp(15px, 1.6216216216vw, 24px) clamp(8px, 0.8783783784vw, 13px);
  border-color: transparent transparent #ffce08 transparent;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.ttl--page .text {
  font-size: 1.6rem;
  font-weight: 400;
  margin-top: 7px;
}

@media screen and (max-width: 767px) {
  /*=================================
    title
  =================================*/
  .ttl--base {
    font-size: 2rem;
    line-height: 1.55;
    margin-bottom: 20px;
  }
  .ttl--base + .ttl--text {
    margin-top: -10px;
    margin-bottom: 20px;
  }
  .ttl--page {
    padding: 0 3.8461538462%;
    margin-bottom: 30px;
  }
  .ttl--page .ttl {
    font-size: 2.4rem;
    padding-left: 20px;
  }
  .ttl--page .ttl::before {
    border-width: 0 6px 13px 6px;
  }
  .ttl--page .text {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.714285;
  }
}
/*=================================
  tab
=================================*/
.tabMenu {
  display: flex;
}
.tabMenu__item {
  font-size: 2.2rem;
  line-height: 1.363636;
  font-weight: 500;
  color: #1a1a1a;
  background: #F3F3F3;
  padding: 30px 24px;
  text-align: center;
  width: 100%;
}
.tabMenu__item:not(.active) {
  cursor: pointer;
}
.tabMenu__item.active {
  background: #4673c6;
  color: #fff;
}
@media screen and (min-width: 767px) {
  .tabMenu__item:hover:not(.active) {
    background: #e7edf8;
  }
}

.tabItem {
  background: #fff;
  min-height: 190px;
}
.tabItem .tabList {
  display: none;
}
.tabItem .tabList.show {
  display: block !important;
}

@media screen and (max-width: 767px) {
  /*=================================
    tab
  =================================*/
  .tabMenu__item {
    font-size: 1.6rem;
    padding: 27px 4.358974359%;
  }
  .tabItem {
    min-height: inherit;
  }
}
/*=================================
  accordion
=================================*/
.js-accordion__btn {
  position: relative;
  cursor: pointer;
}

.js-accordion__item {
  display: none;
}

@media screen and (min-width: 0) and (max-width: 767px) {
  /*=================================
    accordion
  =================================*/
}
/*=================================
  table
=================================*/
table {
  border-collapse: collapse;
  border-spacing: 0;
  margin: 30px 0 50px;
  width: 100%;
  background: #fff;
}
table caption {
  font-size: 1.4rem;
  font-weight: 700;
  margin: 20px 0;
  text-align: left;
}
table thead th, table thead td {
  font-size: 1.6rem;
  font-weight: 700;
  color: #fff;
  text-align: center;
  background: #4673c6;
}
table th {
  font-weight: 700;
  color: #3a67bb;
  text-align: left;
  background: #ebeef4;
}
@media screen and (min-width: 767px) {
  table th {
    width: 176px;
  }
}
table td {
  font-weight: 400;
}
@media screen and (min-width: 767px) {
  table td {
    width: calc(100% - 176px);
  }
}
table tr:not(:first-child) th, table tr:not(:first-child) td {
  border-top: 1px solid #e7e7e7;
}
table th, table td {
  font-size: 1.5rem;
  line-height: 2;
  letter-spacing: 0.06em;
  padding: clamp(13px, 1.2162162162vw, 18px) clamp(20px, 1.6891891892vw, 25px);
  vertical-align: top;
}
table.scroll {
  white-space: nowrap;
  overflow: auto;
  display: block;
  border: none;
}
table.scroll ::-webkit-scrollbar {
  height: 5px;
}
table.scroll ::-webkit-scrollbar-track {
  background: #f1f1f1;
}
table.scroll ::-webkit-scrollbar-thumb {
  background: #bcbcbc;
}

.scrollAnnounce {
  display: none;
}

.wp-block-table table {
  border-collapse: collapse;
  border-spacing: 0;
  margin: 30px 0 50px;
  width: 100%;
  background: #fff;
}
.wp-block-table table caption {
  font-size: 1.4rem;
  font-weight: 700;
  margin: 20px 0;
  text-align: left;
}
.wp-block-table table thead th, .wp-block-table table thead td {
  font-size: 1.6rem;
  font-weight: 700;
  color: #fff;
  text-align: center;
  background: #4673c6;
}
.wp-block-table table th {
  font-weight: 700;
  color: #3a67bb;
  text-align: left;
  background: #ebeef4;
}
@media screen and (min-width: 767px) {
  .wp-block-table table th {
    width: 176px;
  }
}
.wp-block-table table td {
  font-weight: 400;
}
@media screen and (min-width: 767px) {
  .wp-block-table table td {
    width: calc(100% - 176px);
  }
}
.wp-block-table table tr:not(:first-child) th, .wp-block-table table tr:not(:first-child) td {
  border-top: 1px solid #e7e7e7;
}
.wp-block-table table th, .wp-block-table table td {
  font-size: 1.5rem;
  line-height: 2;
  letter-spacing: 0.06em;
  padding: clamp(13px, 1.2162162162vw, 18px) clamp(20px, 1.6891891892vw, 25px);
  vertical-align: top;
}
.wp-block-table table.scroll {
  white-space: nowrap;
  overflow: auto;
  display: block;
  border: none;
}
.wp-block-table table.scroll ::-webkit-scrollbar {
  height: 5px;
}
.wp-block-table table.scroll ::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.wp-block-table table.scroll ::-webkit-scrollbar-thumb {
  background: #bcbcbc;
}

.wp-block-table td, .wp-block-table th {
  border: initial;
}

@media screen and (max-width: 767px) {
  /*=================================
    table
  =================================*/
  table {
    margin: 20px 0 40px;
    /* スクロールのつまみ部分の設定 */
  }
  table:not(.noscroll) {
    display: block;
    white-space: nowrap;
    overflow-x: auto;
    border: none;
  }
  table caption {
    font-size: 1.5rem;
    margin: 15px 0;
  }
  table th, table td {
    padding: 14px 15px;
  }
  table::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  table::-webkit-scrollbar-track {
    border-radius: none;
    background: #d6d6d6;
  }
  table::-webkit-scrollbar-thumb {
    background: #4673c6;
  }
  .wp-block-table table {
    margin: 20px 0 40px;
    /* スクロールのつまみ部分の設定 */
  }
  .wp-block-table table:not(.noscroll) {
    display: block;
    white-space: nowrap;
    overflow-x: auto;
    border: none;
  }
  .wp-block-table table caption {
    font-size: 1.5rem;
    margin: 15px 0;
  }
  .wp-block-table table th, .wp-block-table table td {
    padding: 14px 15px;
  }
  .wp-block-table table::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  .wp-block-table table::-webkit-scrollbar-track {
    border-radius: none;
    background: #d6d6d6;
  }
  .wp-block-table table::-webkit-scrollbar-thumb {
    background: #4673c6;
  }
  .scrollAnnounce {
    display: block;
    text-align: center;
  }
  .scrollAnnounce .text {
    font-size: 1.3rem;
    font-weight: 600;
    color: #fff;
    background: #4673c6;
    padding: 3px 6px;
    position: relative;
  }
  .scrollAnnounce .text::after {
    content: "";
    border-style: solid;
    border-width: 7px 6.5px 0 6.5px;
    border-color: #4673c6 transparent transparent transparent;
    display: inline-block;
    position: absolute;
    bottom: -7px;
    left: 50%;
    transform: translateX(-50%);
  }
}
/*=================================
  breadcrumb
=================================*/
.breadcrumb {
  margin: 20px auto;
  padding: 0 3%;
}
.breadcrumb ul li {
  font-size: 1.4rem;
  line-height: 1;
  color: #B8B8B8;
  display: inline-block;
}
.breadcrumb ul li a {
  font-size: 1.4rem;
  line-height: 1;
  color: #1a1a1a;
  text-decoration: none;
  margin-right: 18px;
}
@media screen and (min-width: 767px) {
  .breadcrumb ul li a:hover {
    color: #3a67bb;
    text-decoration: underline;
  }
}
.breadcrumb ul li a::after {
  content: "";
  width: 6px;
  height: 10px;
  background: url(../img/icon_arrow_breadcrumb.png) no-repeat center center;
  background-size: contain;
  display: inline-block;
  margin-left: 18px;
}

@media screen and (min-width: 0) and (max-width: 767px) {
  /*=================================
    breadcrumb
  =================================*/
  .breadcrumb ul li {
    font-size: 1rem;
    line-height: 1.5;
  }
  .breadcrumb ul li a {
    font-size: 1rem;
    line-height: 1.5;
    margin-right: 7px;
  }
  .breadcrumb ul li a::after {
    margin-left: 7px;
    margin-bottom: -1px;
  }
}
/*=================================
  pager
=================================*/
.pager {
  max-width: 1000px;
  margin-top: 80px;
}
.pagerList {
  display: flex;
  justify-content: center;
}
.pagerList__item {
  margin-right: 6px;
}
.pagerList__item a, .pagerList__item span {
  font-size: 1.5rem;
  line-height: 1;
  padding: 13px 16px;
  display: block;
}
.pagerList__item a {
  color: #4673c6;
  text-decoration: none;
  background: #e7edf8;
}
@media screen and (min-width: 767px) {
  .pagerList__item a:hover {
    color: #fff;
    background: #4673c6;
  }
}
.pagerList__item span {
  color: #fff;
  background: #4673c6;
}
.pager .wp-pagenavi {
  display: flex;
  justify-content: center;
}
.pager .wp-pagenavi .pages {
  font-size: 1.5rem;
  line-height: 1;
  padding: 13px 16px;
  margin-right: 6px;
  border: 1px solid #4673c6;
}
.pager .wp-pagenavi .current {
  font-size: 1.5rem;
  line-height: 1;
  color: #fff;
  padding: 13px 16px;
  background: #4673c6;
  display: block;
  text-decoration: none;
  border: 1px solid #4673c6;
}
.pager .wp-pagenavi .page {
  font-size: 1.5rem;
  color: #4673c6;
  line-height: 1;
  padding: 13px 16px;
  display: block;
  background: #e7edf8;
  text-decoration: none;
  margin-left: 6px;
  border: 1px solid #e7edf8;
}
@media screen and (min-width: 767px) {
  .pager .wp-pagenavi .page:hover {
    color: #fff;
    background: #4673c6;
  }
}
.pager .wp-pagenavi .nextpostslink {
  font-size: 1.5rem;
  color: #4673c6;
  line-height: 1;
  padding: 13px 16px;
  display: block;
  background: #fff;
  text-decoration: none;
  margin-left: 6px;
  border: 1px solid #4673c6;
}
@media screen and (min-width: 767px) {
  .pager .wp-pagenavi .nextpostslink:hover {
    color: #fff;
    background: #4673c6;
  }
}
.pager .wp-pagenavi .previouspostslink {
  font-size: 1.5rem;
  color: #4673c6;
  line-height: 1;
  padding: 13px 16px;
  display: block;
  background: #fff;
  text-decoration: none;
  border: 1px solid #4673c6;
}
@media screen and (min-width: 767px) {
  .pager .wp-pagenavi .previouspostslink:hover {
    color: #fff;
    background: #4673c6;
  }
}

@media screen and (max-width: 767px) {
  /*=================================
    pager
  =================================*/
  .pager {
    display: block;
    text-align: center;
    margin-top: 40px;
  }
  .pagerList {
    justify-content: center;
  }
  .pagerList__item {
    margin-right: 8px;
  }
  .pagerList__item a, .pagerList__item span {
    padding: 11px 14px;
  }
  .pager .wp-pagenavi .pages {
    padding: 11px 14px;
    margin-right: 8px;
  }
  .pager .wp-pagenavi .current {
    padding: 11px 14px;
  }
  .pager .wp-pagenavi .page {
    padding: 11px 14px;
    margin-left: 8px;
  }
  .pager .wp-pagenavi .nextpostslink {
    padding: 11px 14px;
    margin-left: 8px;
  }
  .pager .wp-pagenavi .previouspostslink {
    padding: 11px 14px;
  }
}
/*=================================
  topPage
=================================*/
.home .vision {
  padding: clamp(55px, 7.4324324324vw, 110px) 0 0;
  background: #fff;
}
.home .visionCont {
  background: url(../img/vision_bg.png) no-repeat right top #4673c6;
  background-size: clamp(322px, 35.2702702703vw, 522px) auto;
  position: relative;
  margin-top: clamp(89px, 9.0540540541vw, 134px);
  padding: 1% 8%;
}
.home .visionCont__inner {
  max-width: 1180px;
  margin: 0 auto;
  display: flex;
  align-items: center;
}
.home .visionCont__inner .vision__img {
  max-width: clamp(260px, 37.8378378378vw, 560px);
  width: 50%;
  transform: translate(-14.2857%, -13.25%);
  align-self: flex-start;
}
.home .visionCont__inner .vision__text {
  font-size: clamp(1.4rem, 1.0810810811vw, 1.6rem);
  font-weight: 400;
  line-height: 2.25;
  letter-spacing: 0.12em;
  color: #fff;
}
.home .visionCont__inner .vision__text p + p {
  margin-top: 1rem;
}
.home .media {
  padding: clamp(52px, 7.0945945946vw, 105px) 0 0;
  /* slider設定 */
}
.home .media .mediaList {
  display: flex;
  justify-content: center;
  background: #e7edf8;
}
.home .media .mediaList .sliderWrap {
  width: 33.3333333333%;
  height: initial;
}
.home .media .mediaList__item {
  background: #e7edf8;
  text-align: center;
  padding: clamp(35px, 4.7297297297vw, 70px) 5% clamp(54px, 7.2972972973vw, 108px);
  height: 100%;
}
.home .media .mediaList__item .media__img {
  margin: 0 auto clamp(25px, 3.3783783784vw, 50px);
  display: block;
}
.home .media .mediaList__item .media__label {
  font-family: "Montserrat", sans-serif;
  font-size: clamp(1.3rem, 1.0810810811vw, 1.6rem);
  font-weight: 700;
  letter-spacing: 0.16em;
  color: #4673c6;
}
.home .media .mediaList__item .media__ttl {
  font-size: clamp(1.6rem, 1.5540540541vw, 2.3rem);
  font-weight: 700;
  letter-spacing: 0.06em;
  margin: clamp(9px, 1.2162162162vw, 18px) 0 0;
}
.home .media .mediaList__item .media__text {
  font-size: clamp(1.2rem, 0.9459459459vw, 1.4rem);
  font-weight: 400;
  letter-spacing: 0.12em;
  margin: clamp(11px, 1.5540540541vw, 23px) 0 0;
}
.home .media .mediaList__item .media__btn {
  font-family: "Montserrat", sans-serif;
  font-size: clamp(1.2rem, 0.8783783784vw, 1.3rem);
  font-weight: 700;
  letter-spacing: 0.16em;
  color: #fff;
  text-decoration: none;
  background: #4673c6;
  text-align: center;
  border-radius: 50px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
  display: block;
  width: 88%;
  margin: clamp(16px, 2.1621621622vw, 32px) auto 0;
  padding: clamp(7px, 0.9459459459vw, 14px) 10px;
  position: relative;
  overflow: hidden;
}
.home .media .mediaList__item .media__btn::after {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  background: #ffce08;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  /*アニメーション*/
  transition: transform 0.6s cubic-bezier(0.8, 0, 0.2, 1) 0s;
  transform: scale(0, 1);
  transform-origin: right top;
}
@media screen and (min-width: 767px) {
  .home .media .mediaList__item .media__btn:hover {
    box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.2);
  }
  .home .media .mediaList__item .media__btn:hover::after {
    transform: scale(1, 1);
    transform-origin: left top;
  }
  .home .media .mediaList__item .media__btn:hover span::after {
    transform: translateX(10px);
  }
}
.home .media .mediaList__item .media__btn span {
  position: relative;
  z-index: 2;
}
.home .media .mediaList__item .media__btn span::after {
  content: "";
  width: 15px;
  height: 8px;
  display: inline-block;
  background: url(../img/arrow_white_pc.png) no-repeat center center;
  background-size: contain;
  margin: 0 0 0.15rem 1rem;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
}
.home .media .mediaList__item .media__btn--sub {
  font-family: "Montserrat", "Noto Sans JP", "源ノ角ゴシック", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", sans-serif;
  font-size: clamp(1.2rem, 0.8783783784vw, 1.3rem);
  font-weight: 700;
  letter-spacing: 0.16em;
  color: #fff;
  text-decoration: none;
  background: #c63033;
  text-align: center;
  border-radius: 50px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
  display: block;
  width: 88%;
  margin: clamp(16px, 2.1621621622vw, 32px) auto 0;
  padding: clamp(7px, 0.9459459459vw, 14px) 10px;
  position: relative;
  overflow: hidden;
}
.home .media .mediaList__item .media__btn--sub::after {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  background: #ffce08;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  /*アニメーション*/
  transition: transform 0.6s cubic-bezier(0.8, 0, 0.2, 1) 0s;
  transform: scale(0, 1);
  transform-origin: right top;
}
@media screen and (min-width: 767px) {
  .home .media .mediaList__item .media__btn--sub:hover {
    box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.2);
  }
  .home .media .mediaList__item .media__btn--sub:hover::after {
    transform: scale(1, 1);
    transform-origin: left top;
  }
  .home .media .mediaList__item .media__btn--sub:hover span::after {
    transform: translateX(10px);
  }
}
.home .media .mediaList__item .media__btn--sub span {
  position: relative;
  z-index: 2;
}
.home .media .mediaList__item .media__btn--sub span::after {
  content: "";
  width: 15px;
  height: 8px;
  display: inline-block;
  background: url(../img/arrow_white_pc.png) no-repeat center center;
  background-size: contain;
  margin: 0 0 0.15rem 1rem;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
}
.home .media .slider--media {
  /* Arrows */
  /* Dots */
}
.home .media .slider--media.slick-slider {
  padding: 0 46px;
}
.home .media .slider--media.slick-slider .slick-track {
  display: flex;
}
.home .media .slider--media:not(.slick-slider) .sliderWrap:nth-child(2n) .mediaList__item {
  background: #f0f4fb;
}
.home .media .slider--media .slick-current .mediaList__item {
  background: #f0f4fb;
}
.home .media .slider--media .slick-prev,
.home .media .slider--media .slick-next {
  top: 40%;
  width: initial;
  height: initial;
  transform: translate(0, 0);
  z-index: 1;
}
.home .media .slider--media .slick-prev:before,
.home .media .slider--media .slick-next:before {
  content: "";
  width: 23px;
  height: 12px;
  display: inline-block;
  opacity: 1;
  background: url(../img/arrow_blue_pc.png) no-repeat center center;
  background-size: contain;
}
.home .media .slider--media .slick-prev {
  left: 1.081%;
}
.home .media .slider--media [dir=rtl] .slick-prev {
  right: 1.081%;
  left: auto;
}
.home .media .slider--media [dir=rtl] .slick-prev:before {
  content: "";
}
.home .media .slider--media .slick-next {
  right: 1.081%;
  transform: rotateY(180deg);
}
.home .media .slider--media [dir=rtl] .slick-next {
  right: auto;
  left: 1.081%;
}
.home .media .slider--media [dir=rtl] .slick-next:before {
  content: "";
}
.home .media .slider--media.slick-dotted {
  margin-bottom: 0 !important;
}
.home .media .slider--media .slick-dots {
  bottom: clamp(15px, 2.3648648649vw, 35px);
}
.home .media .slider--media .slick-dots li {
  width: clamp(10px, 0.8783783784vw, 13px);
  height: clamp(10px, 0.8783783784vw, 13px);
  margin: 0 clamp(4px, 0.6081081081vw, 9px);
}
.home .media .slider--media .slick-dots li button {
  width: clamp(10px, 0.8783783784vw, 13px);
  height: clamp(10px, 0.8783783784vw, 13px);
  padding: 0;
}
.home .media .slider--media .slick-dots li button:before {
  content: "";
  width: clamp(10px, 0.8783783784vw, 13px);
  height: clamp(10px, 0.8783783784vw, 13px);
  display: inline-block;
  border-radius: 50px;
  background: #d6d9e0;
  opacity: 1;
  color: #d6d9e0;
}
.home .media .slider--media .slick-dots li.slick-active button:before {
  opacity: 1;
  color: #688dd1;
  background: #688dd1;
}
.home .company {
  padding: clamp(55px, 7.4324324324vw, 110px) 3%;
  background: url(../img/company_bg.jpg) repeat left top;
}
.home .company__inner {
  max-width: 1180px;
  margin: 0 auto;
}
.home .company__table {
  max-width: 900px;
  margin: 0 auto;
}
.home .access {
  padding: clamp(55px, 7.4324324324vw, 110px) 3%;
  background: url(../img/access_bg.jpg) no-repeat center center;
  background-size: cover;
}
.home .access__inner {
  max-width: 1180px;
  margin: 0 auto;
}
.home .access__gMap {
  width: 100%;
  padding-top: 48.645%;
  border: 3px solid #cbd5e8;
  overflow: hidden;
  position: relative;
}
.home .access__gMap iframe {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

/* メインビジュアル */
.kv {
  background: url(../img/kv_bg_pc.jpg) no-repeat center top;
  background-size: cover;
  padding: 0 3%;
  height: clamp(56vh, 50vw, 86vh);
  position: relative;
}
.kv__catch {
  font-size: clamp(4.6rem, 4.4594594595vw, 6.6rem);
  font-weight: 300;
  font-feature-settings: "palt";
  line-height: 1.272727;
  letter-spacing: 0.12em;
  color: #fff;
  text-shadow: 0px 0px 14px rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 3%;
  bottom: 6%;
}

@media screen and (max-width: 767px) {
  /*=================================
    topPage
  =================================*/
  .home .vision {
    padding: 50px 0 0;
  }
  .home .visionCont {
    background: url(../img/vision_bg.png) no-repeat left top #4673c6;
    background-size: 85% auto;
    margin-top: 30px;
    padding: 7.6923076923% 3.8461538462% 0;
  }
  .home .visionCont__inner {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  .home .visionCont__inner .vision__img {
    max-width: initial;
    width: 80%;
    height: 150px;
    transform: translate(5.1282051282%, 15px);
    align-self: flex-end;
    object-fit: cover;
    object-position: center;
  }
  .home .visionCont__inner .vision__text {
    font-size: 1.5rem;
  }
  .home .media {
    padding: 50px 0 0;
    /* slider設定 */
  }
  .home .media .mediaList .sliderWrap {
    width: 100%;
  }
  .home .media .mediaList__item {
    padding: 35px 3.8461538462% 50px;
  }
  .home .media .mediaList__item .media__img {
    max-width: 80%;
    margin: 0 auto 25px;
  }
  .home .media .mediaList__item .media__label {
    font-size: 1.5rem;
  }
  .home .media .mediaList__item .media__ttl {
    font-size: 1.6rem;
    margin: 10px 0 0;
  }
  .home .media .mediaList__item .media__text {
    font-size: 1.4rem;
    margin: 10px 0 0;
  }
  .home .media .mediaList__item .media__btn {
    font-size: 1.2rem;
    margin: 16px auto 0;
    padding: 10px 3.8461538462%;
  }
  .home .media .mediaList__item .media__btn::after {
    content: none;
  }
  .home .media .mediaList__item .media__btn--sub {
    font-size: 1.2rem;
    margin: 16px auto 0;
    padding: 10px 3.8461538462%;
  }
  .home .media .mediaList__item .media__btn--sub::after {
    content: none;
  }
  .home .media .slider--media {
    /* Arrows */
    /* Dots */
  }
  .home .media .slider--media.slick-slider {
    padding: 0 10.2564102564%;
  }
  .home .media .slider--media .slick-prev,
  .home .media .slider--media .slick-next {
    top: 50%;
  }
  .home .media .slider--media .slick-prev:before,
  .home .media .slider--media .slick-next:before {
    width: 20px;
    height: 9px;
    background: url(../img/arrow_blue_sp.png) no-repeat center center;
    background-size: contain;
  }
  .home .media .slider--media .slick-prev {
    left: 2.5641025641%;
  }
  .home .media .slider--media [dir=rtl] .slick-prev {
    right: 2.5641025641%;
  }
  .home .media .slider--media .slick-next {
    right: 2.5641025641%;
  }
  .home .media .slider--media [dir=rtl] .slick-next {
    left: 2.5641025641%;
  }
  .home .media .slider--media .slick-dots {
    bottom: 15px;
  }
  .home .media .slider--media .slick-dots li {
    width: 10px;
    height: 10px;
    margin: 0 8px;
  }
  .home .media .slider--media .slick-dots li button {
    width: 10px;
    height: 10px;
  }
  .home .media .slider--media .slick-dots li button:before {
    width: 10px;
    height: 10px;
  }
  .home .company {
    padding: 50px 3.8461538462%;
  }
  .home .company__table {
    max-width: initial;
  }
  .home .company__table th {
    width: 12rem;
  }
  .home .access {
    padding: 50px 3.8461538462%;
  }
  .home .access__gMap {
    border: 2px solid #cbd5e8;
  }
  /* メインビジュアル */
  .kv {
    background: url(../img/kv_bg_pc.jpg) no-repeat right 20% center;
    background-size: cover;
    padding: 0 3.8461538462%;
    height: 70vh;
  }
  .kv__catch {
    font-size: 4.2rem;
    left: 3.8461538462%;
  }
}
/*=================================
  page--article
=================================*/
/* 記事一覧 */
.articleList {
  position: relative;
  z-index: 1;
}
.articleList__item {
  background: #F9F9F9;
  border-radius: 15px;
  max-width: 1000px;
}
@media screen and (min-width: 767px) {
  .articleList__item:hover .textWrap__ttl {
    text-decoration: underline !important;
  }
}
.articleList__item .itemWrap {
  padding: 40px 40px 40px 50px;
  display: flex;
  justify-content: flex-end;
  flex-direction: row-reverse;
  flex-wrap: wrap;
}
.articleList__item .itemWrap .thumb {
  width: 38%;
  max-width: 350px;
  margin-left: 2.8%;
  border-radius: 5px;
  overflow: hidden;
}
.articleList__item .itemWrap .thumb img {
  margin: 0 auto;
}
.articleList__item .itemWrap .thumb + .textWrap {
  width: 59.2%;
}
.articleList__item .itemWrap .textWrap {
  width: 100%;
}
.articleList__item .itemWrap .textWrap__date {
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 14px;
}
.articleList__item .itemWrap .textWrap__ttl {
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 1.66666;
  display: block;
  text-decoration: none;
  position: relative;
}
.articleList__item .itemWrap .textWrap__ttl::before {
  content: "";
  width: 8px;
  height: 8px;
  display: inline-block;
  background: #4673c6;
  border-radius: 50px;
  position: absolute;
  top: 16px;
  left: -20px;
}
.articleList__item .itemWrap .textWrap__text {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2;
  margin-top: 20px;
}
.articleList__item + .articleList__item {
  margin-top: 40px;
}

.articleListWrap {
  padding: 100px 3%;
  margin-top: 50px;
  background: #F9F9F9;
}
.articleListWrap__in {
  padding: 70px 70px 40px;
  background: #fff;
  border-radius: 20px;
  max-width: 1180px;
  margin: 0 auto;
}
.articleListWrap__in h1, .articleListWrap__in h2, .articleListWrap__in h3, .articleListWrap__in h4, .articleListWrap__in h5, .articleListWrap__in h6 {
  clear: both;
}
.articleListWrap__in p:not([class]) {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2;
}
.articleListWrap__in p:not([class]) + p {
  margin-top: 20px;
}
.articleListWrap__in h1:not([class]), .articleListWrap__in h1.wp-block-heading {
  font-size: 4.2rem;
  font-weight: 700;
  line-height: 1.33333;
  margin-top: 100px;
  margin-bottom: 40px;
  padding-left: 25px;
  position: relative;
}
.articleListWrap__in h1:not([class])::before, .articleListWrap__in h1.wp-block-heading::before {
  content: "";
  width: 12px;
  height: 12px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50px;
  background: #4673c6;
  position: absolute;
  top: 25px;
  left: 0;
}
.articleListWrap__in h2:not([class]), .articleListWrap__in h2.wp-block-heading {
  font-size: 2.4rem;
  font-weight: 700;
  color: #fff;
  line-height: 1.66666;
  margin-top: 100px;
  margin-bottom: 40px;
  padding: 28px 30px;
  background: #4673c6;
}
@media screen and (min-width: 767px) and (max-width: 1480px) {
  .articleListWrap__in h2:not([class]), .articleListWrap__in h2.wp-block-heading {
    padding: 1.8918918919vw 2.027027027vw;
  }
}
.articleListWrap__in h3:not([class]), .articleListWrap__in h3.wp-block-heading {
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.5;
  margin-top: 60px;
  margin-bottom: 30px;
  padding-bottom: 20px;
  border-bottom: 3px solid #cbd5e8;
  position: relative;
}
.articleListWrap__in h3:not([class])::after, .articleListWrap__in h3.wp-block-heading::after {
  content: "";
  width: 9em;
  height: 3px;
  display: inline-block;
  background: #4673c6;
  position: absolute;
  left: 0;
  bottom: -3px;
}
.articleListWrap__in h4:not([class]), .articleListWrap__in h4.wp-block-heading {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.5;
  margin-top: 60px;
  margin-bottom: 20px;
  padding-left: 29px;
  position: relative;
}
.articleListWrap__in h4:not([class])::before, .articleListWrap__in h4.wp-block-heading::before {
  content: "";
  width: 8px;
  height: 8px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50px;
  background: #4673c6;
  position: absolute;
  top: 10px;
  left: 8px;
}
.articleListWrap__in h5:not([class]), .articleListWrap__in h5.wp-block-heading {
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.44444;
  color: #4673c6;
  margin-top: 40px;
  margin-bottom: 20px;
  padding: 10px 15px;
  border-left: 4px solid #4673c6;
  background: #F9F9F9;
}
.articleListWrap__in h6:not([class]), .articleListWrap__in h6.wp-block-heading {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.77777;
  color: #4673c6;
  margin-top: 40px;
  margin-bottom: 20px;
  padding-left: 20px;
  position: relative;
}
.articleListWrap__in h6:not([class])::before, .articleListWrap__in h6.wp-block-heading::before {
  content: "";
  width: 10px;
  height: 10px;
  display: inline-block;
  background: #4673c6;
  position: absolute;
  top: 12px;
  left: 0;
}
.articleListWrap__in blockquote {
  position: relative;
  padding: 50px 20px 20px;
  margin: 50px 0 30px;
  box-sizing: border-box;
  font-style: italic;
  color: #464646;
  border: 2px solid #e7edf8;
}
.articleListWrap__in blockquote::before {
  content: "“";
  font-size: 5rem;
  font-weight: 700;
  color: #4673c6;
  display: inline-block;
  font-style: normal;
  width: 50px;
  height: 50px;
  line-height: 1.3;
  text-align: center;
  background: #e7edf8;
  border-radius: 50px;
  position: absolute;
  top: -25px;
  left: 15px;
}
.articleListWrap__in blockquote h1 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.articleListWrap__in blockquote h2 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.articleListWrap__in blockquote h3 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.articleListWrap__in blockquote h4 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.articleListWrap__in blockquote h5 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.articleListWrap__in blockquote h6 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.articleListWrap__in blockquote p {
  font-style: normal;
}
.articleListWrap__in blockquote cite {
  display: block;
  text-align: right;
  line-height: 1;
  color: #888888;
  font-size: 0.9em;
  margin: 10px 0 0;
}
.articleListWrap__in .btnWrap {
  clear: both;
}
.articleListWrap__in img:not([class]), .articleListWrap__in.wp-block-heading {
  display: block;
  margin: 15px auto 20px;
}
.articleListWrap__in img.alignleft {
  float: left;
  margin: 0 30px 30px 0;
}
.articleListWrap__in img.alignright {
  float: right;
  margin: 0 0 30px 30px;
}
.articleListWrap__in ul:not([class]) {
  margin: 30px 0;
  padding: 30px;
  background: #FFF;
  border: 2px solid #cbd5e8;
  clear: both;
}
@media screen and (min-width: 767px) and (max-width: 1480px) {
  .articleListWrap__in ul:not([class]) {
    padding: 2.027027027vw;
  }
}
.articleListWrap__in ul:not([class]) li {
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 2;
  padding-left: 18px;
  position: relative;
}
.articleListWrap__in ul:not([class]) li::before {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50px;
  background: #4673c6;
  display: inline-block;
  position: absolute;
  top: 13px;
  left: 0;
}
.articleListWrap__in ul:not([class]) li + li {
  margin-top: 10px;
}
.articleListWrap__in ul:not([class]) h1::before {
  content: none;
}
.articleListWrap__in ul:not([class]) h2::before {
  content: none;
}
.articleListWrap__in ul:not([class]) h3::before {
  content: none;
}
.articleListWrap__in ul:not([class]) h4::before {
  content: none;
}
.articleListWrap__in ul:not([class]) h5::before {
  content: none;
}
.articleListWrap__in ul:not([class]) h6::before {
  content: none;
}
.articleListWrap__in ol:not([class]) {
  margin: 30px 0;
  padding: 30px;
  background: #e7edf8;
  counter-reset: listNum;
  clear: both;
}
.articleListWrap__in ol:not([class]) li {
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 2;
  padding-left: 38px;
  position: relative;
}
.articleListWrap__in ol:not([class]) li::before {
  counter-increment: listNum;
  content: counter(listNum);
  font-size: 1.6rem;
  font-weight: 700;
  color: #4673c6;
  width: 28px;
  text-align: center;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}
.articleListWrap__in ol:not([class]) li + li {
  margin-top: 10px;
}
.articleListWrap__in ol:not([class]) h1::before {
  content: none;
}
.articleListWrap__in ol:not([class]) h2::before {
  content: none;
}
.articleListWrap__in ol:not([class]) h3::before {
  content: none;
}
.articleListWrap__in ol:not([class]) h4::before {
  content: none;
}
.articleListWrap__in ol:not([class]) h5::before {
  content: none;
}
.articleListWrap__in ol:not([class]) h6::before {
  content: none;
}
@media screen and (min-width: 0) and (max-width: 767px) {
  .articleListWrap__in p:not([class]) {
    font-size: 1.4rem;
    line-height: 1.85714;
  }
  .articleListWrap__in p:not([class]) + p {
    margin-top: 10px;
  }
  .articleListWrap__in h1:not([class]), .articleListWrap__in h1.wp-block-heading {
    font-size: 2.4rem;
    margin-top: 54px;
    margin-bottom: 20px;
    padding-left: 15px;
  }
  .articleListWrap__in h1:not([class])::before, .articleListWrap__in h1.wp-block-heading::before {
    width: 8px;
    height: 8px;
    top: 13px;
  }
  .articleListWrap__in h2:not([class]), .articleListWrap__in h2.wp-block-heading {
    font-size: 1.8rem;
    line-height: 1.38888;
    margin-top: 54px;
    margin-bottom: 20px;
    padding: 15px 7.6923076923%;
    background: #4673c6;
  }
  .articleListWrap__in h3:not([class]), .articleListWrap__in h3.wp-block-heading {
    font-size: 1.6rem;
    line-height: 1.625;
    margin-top: 40px;
    margin-bottom: 20px;
    padding-bottom: 14px;
  }
  .articleListWrap__in h3:not([class])::after, .articleListWrap__in h3.wp-block-heading::after {
    width: 6.5em;
  }
  .articleListWrap__in h4:not([class]), .articleListWrap__in h4.wp-block-heading {
    font-size: 1.6rem;
    line-height: 1.625;
    margin-top: 40px;
    padding-left: 20px;
  }
  .articleListWrap__in h4:not([class])::before, .articleListWrap__in h4.wp-block-heading::before {
    top: 10px;
    left: 5px;
  }
  .articleListWrap__in h5:not([class]), .articleListWrap__in h5.wp-block-heading {
    font-size: 1.5rem;
    line-height: 1.73333;
    padding: 10px 12px;
  }
  .articleListWrap__in h6:not([class]), .articleListWrap__in h6.wp-block-heading {
    font-size: 1.5rem;
    line-height: 1.73333;
    padding-left: 18px;
  }
  .articleListWrap__in h6:not([class])::before, .articleListWrap__in h6.wp-block-heading::before {
    width: 8px;
    height: 8px;
    top: 9px;
  }
  .articleListWrap__in blockquote {
    padding: 40px 3.8461538462% 20px;
    margin: 40px 0 30px;
    box-sizing: border-box;
    font-style: italic;
    color: #464646;
    border: 2px solid #e7edf8;
  }
  .articleListWrap__in blockquote::before {
    font-size: 4rem;
    width: 45px;
    height: 45px;
    line-height: 1.5;
  }
  .articleListWrap__in blockquote h1 {
    font-size: 1.5rem;
  }
  .articleListWrap__in blockquote h2 {
    font-size: 1.5rem;
  }
  .articleListWrap__in blockquote h3 {
    font-size: 1.5rem;
  }
  .articleListWrap__in blockquote h4 {
    font-size: 1.5rem;
  }
  .articleListWrap__in blockquote h5 {
    font-size: 1.5rem;
  }
  .articleListWrap__in blockquote h6 {
    font-size: 1.5rem;
  }
  .articleListWrap__in img.alignleft {
    float: none;
    display: block;
    margin: 15px auto 20px;
  }
  .articleListWrap__in img.alignright {
    float: none;
    display: block;
    margin: 15px auto 20px;
  }
  .articleListWrap__in ul:not([class]) {
    margin: 20px 0;
    padding: 25px 6.4102564103%;
  }
  .articleListWrap__in ul:not([class]) li {
    font-size: 1.4rem;
    line-height: 1.71428;
    padding-left: 14px;
  }
  .articleListWrap__in ul:not([class]) li::before {
    width: 6px;
    height: 6px;
    top: 8px;
  }
  .articleListWrap__in ol:not([class]) {
    margin: 20px 0;
    padding: 25px 3.8461538462%;
  }
  .articleListWrap__in ol:not([class]) li {
    font-size: 1.4rem;
    line-height: 1.71428;
  }
  .articleListWrap__in ol:not([class]) li::before {
    font-size: 1.4rem;
  }
}
.articleListWrap__in iframe {
  width: 100%;
}
.articleListWrap__in > h1:first-child {
  margin-top: 0 !important;
}
.articleListWrap__in > h2:first-child {
  margin-top: 0 !important;
}
.articleListWrap__in > h3:first-child {
  margin-top: 0 !important;
}
.articleListWrap__in > h4:first-child {
  margin-top: 0 !important;
}
.articleListWrap__in > h5:first-child {
  margin-top: 0 !important;
}
.articleListWrap__in > h6:first-child {
  margin-top: 0 !important;
}
@media screen and (min-width: 767px) and (max-width: 1480px) {
  .articleListWrap__in {
    padding: 70px 4.7297297297vw 40px;
  }
}
.articleListWrap__in .pager + h1 {
  margin-top: 100px;
}
.articleListWrap__in .pager + h2 {
  margin-top: 100px;
}
.articleListWrap__in .pager + h3 {
  margin-top: 100px;
}
.articleListWrap__in .pager + h4 {
  margin-top: 100px;
}
.articleListWrap__in .pager + h5 {
  margin-top: 100px;
}
.articleListWrap__in .pager + h6 {
  margin-top: 100px;
}

/* singlePage */
.singlePage {
  padding: 100px 3%;
  margin-top: 50px;
  background: #F9F9F9;
  /*---------------------
    メインビジュアル
  ---------------------*/
  /*---------------------
    目次
  ---------------------*/
}
.singlePage__in {
  padding: 70px 70px 40px;
  background: #fff;
  border-radius: 20px;
  max-width: 1180px;
  margin: 0 auto;
}
.singlePage__in h1, .singlePage__in h2, .singlePage__in h3, .singlePage__in h4, .singlePage__in h5, .singlePage__in h6 {
  clear: both;
}
.singlePage__in p:not([class]) {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2;
}
.singlePage__in p:not([class]) + p {
  margin-top: 20px;
}
.singlePage__in h1:not([class]), .singlePage__in h1.wp-block-heading {
  font-size: 4.2rem;
  font-weight: 700;
  line-height: 1.33333;
  margin-top: 100px;
  margin-bottom: 40px;
  padding-left: 25px;
  position: relative;
}
.singlePage__in h1:not([class])::before, .singlePage__in h1.wp-block-heading::before {
  content: "";
  width: 12px;
  height: 12px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50px;
  background: #4673c6;
  position: absolute;
  top: 25px;
  left: 0;
}
.singlePage__in h2:not([class]), .singlePage__in h2.wp-block-heading {
  font-size: 2.4rem;
  font-weight: 700;
  color: #fff;
  line-height: 1.66666;
  margin-top: 100px;
  margin-bottom: 40px;
  padding: 28px 30px;
  background: #4673c6;
}
@media screen and (min-width: 767px) and (max-width: 1480px) {
  .singlePage__in h2:not([class]), .singlePage__in h2.wp-block-heading {
    padding: 1.8918918919vw 2.027027027vw;
  }
}
.singlePage__in h3:not([class]), .singlePage__in h3.wp-block-heading {
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.5;
  margin-top: 60px;
  margin-bottom: 30px;
  padding-bottom: 20px;
  border-bottom: 3px solid #cbd5e8;
  position: relative;
}
.singlePage__in h3:not([class])::after, .singlePage__in h3.wp-block-heading::after {
  content: "";
  width: 9em;
  height: 3px;
  display: inline-block;
  background: #4673c6;
  position: absolute;
  left: 0;
  bottom: -3px;
}
.singlePage__in h4:not([class]), .singlePage__in h4.wp-block-heading {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.5;
  margin-top: 60px;
  margin-bottom: 20px;
  padding-left: 29px;
  position: relative;
}
.singlePage__in h4:not([class])::before, .singlePage__in h4.wp-block-heading::before {
  content: "";
  width: 8px;
  height: 8px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50px;
  background: #4673c6;
  position: absolute;
  top: 10px;
  left: 8px;
}
.singlePage__in h5:not([class]), .singlePage__in h5.wp-block-heading {
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.44444;
  color: #4673c6;
  margin-top: 40px;
  margin-bottom: 20px;
  padding: 10px 15px;
  border-left: 4px solid #4673c6;
  background: #F9F9F9;
}
.singlePage__in h6:not([class]), .singlePage__in h6.wp-block-heading {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.77777;
  color: #4673c6;
  margin-top: 40px;
  margin-bottom: 20px;
  padding-left: 20px;
  position: relative;
}
.singlePage__in h6:not([class])::before, .singlePage__in h6.wp-block-heading::before {
  content: "";
  width: 10px;
  height: 10px;
  display: inline-block;
  background: #4673c6;
  position: absolute;
  top: 12px;
  left: 0;
}
.singlePage__in blockquote {
  position: relative;
  padding: 50px 20px 20px;
  margin: 50px 0 30px;
  box-sizing: border-box;
  font-style: italic;
  color: #464646;
  border: 2px solid #e7edf8;
}
.singlePage__in blockquote::before {
  content: "“";
  font-size: 5rem;
  font-weight: 700;
  color: #4673c6;
  display: inline-block;
  font-style: normal;
  width: 50px;
  height: 50px;
  line-height: 1.3;
  text-align: center;
  background: #e7edf8;
  border-radius: 50px;
  position: absolute;
  top: -25px;
  left: 15px;
}
.singlePage__in blockquote h1 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.singlePage__in blockquote h2 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.singlePage__in blockquote h3 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.singlePage__in blockquote h4 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.singlePage__in blockquote h5 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.singlePage__in blockquote h6 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.singlePage__in blockquote p {
  font-style: normal;
}
.singlePage__in blockquote cite {
  display: block;
  text-align: right;
  line-height: 1;
  color: #888888;
  font-size: 0.9em;
  margin: 10px 0 0;
}
.singlePage__in .btnWrap {
  clear: both;
}
.singlePage__in img:not([class]), .singlePage__in.wp-block-heading {
  display: block;
  margin: 15px auto 20px;
}
.singlePage__in img.alignleft {
  float: left;
  margin: 0 30px 30px 0;
}
.singlePage__in img.alignright {
  float: right;
  margin: 0 0 30px 30px;
}
.singlePage__in ul:not([class]) {
  margin: 30px 0;
  padding: 30px;
  background: #FFF;
  border: 2px solid #cbd5e8;
  clear: both;
}
@media screen and (min-width: 767px) and (max-width: 1480px) {
  .singlePage__in ul:not([class]) {
    padding: 2.027027027vw;
  }
}
.singlePage__in ul:not([class]) li {
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 2;
  padding-left: 18px;
  position: relative;
}
.singlePage__in ul:not([class]) li::before {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50px;
  background: #4673c6;
  display: inline-block;
  position: absolute;
  top: 13px;
  left: 0;
}
.singlePage__in ul:not([class]) li + li {
  margin-top: 10px;
}
.singlePage__in ul:not([class]) h1::before {
  content: none;
}
.singlePage__in ul:not([class]) h2::before {
  content: none;
}
.singlePage__in ul:not([class]) h3::before {
  content: none;
}
.singlePage__in ul:not([class]) h4::before {
  content: none;
}
.singlePage__in ul:not([class]) h5::before {
  content: none;
}
.singlePage__in ul:not([class]) h6::before {
  content: none;
}
.singlePage__in ol:not([class]) {
  margin: 30px 0;
  padding: 30px;
  background: #e7edf8;
  counter-reset: listNum;
  clear: both;
}
.singlePage__in ol:not([class]) li {
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 2;
  padding-left: 38px;
  position: relative;
}
.singlePage__in ol:not([class]) li::before {
  counter-increment: listNum;
  content: counter(listNum);
  font-size: 1.6rem;
  font-weight: 700;
  color: #4673c6;
  width: 28px;
  text-align: center;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}
.singlePage__in ol:not([class]) li + li {
  margin-top: 10px;
}
.singlePage__in ol:not([class]) h1::before {
  content: none;
}
.singlePage__in ol:not([class]) h2::before {
  content: none;
}
.singlePage__in ol:not([class]) h3::before {
  content: none;
}
.singlePage__in ol:not([class]) h4::before {
  content: none;
}
.singlePage__in ol:not([class]) h5::before {
  content: none;
}
.singlePage__in ol:not([class]) h6::before {
  content: none;
}
@media screen and (min-width: 0) and (max-width: 767px) {
  .singlePage__in p:not([class]) {
    font-size: 1.4rem;
    line-height: 1.85714;
  }
  .singlePage__in p:not([class]) + p {
    margin-top: 10px;
  }
  .singlePage__in h1:not([class]), .singlePage__in h1.wp-block-heading {
    font-size: 2.4rem;
    margin-top: 54px;
    margin-bottom: 20px;
    padding-left: 15px;
  }
  .singlePage__in h1:not([class])::before, .singlePage__in h1.wp-block-heading::before {
    width: 8px;
    height: 8px;
    top: 13px;
  }
  .singlePage__in h2:not([class]), .singlePage__in h2.wp-block-heading {
    font-size: 1.8rem;
    line-height: 1.38888;
    margin-top: 54px;
    margin-bottom: 20px;
    padding: 15px 7.6923076923%;
    background: #4673c6;
  }
  .singlePage__in h3:not([class]), .singlePage__in h3.wp-block-heading {
    font-size: 1.6rem;
    line-height: 1.625;
    margin-top: 40px;
    margin-bottom: 20px;
    padding-bottom: 14px;
  }
  .singlePage__in h3:not([class])::after, .singlePage__in h3.wp-block-heading::after {
    width: 6.5em;
  }
  .singlePage__in h4:not([class]), .singlePage__in h4.wp-block-heading {
    font-size: 1.6rem;
    line-height: 1.625;
    margin-top: 40px;
    padding-left: 20px;
  }
  .singlePage__in h4:not([class])::before, .singlePage__in h4.wp-block-heading::before {
    top: 10px;
    left: 5px;
  }
  .singlePage__in h5:not([class]), .singlePage__in h5.wp-block-heading {
    font-size: 1.5rem;
    line-height: 1.73333;
    padding: 10px 12px;
  }
  .singlePage__in h6:not([class]), .singlePage__in h6.wp-block-heading {
    font-size: 1.5rem;
    line-height: 1.73333;
    padding-left: 18px;
  }
  .singlePage__in h6:not([class])::before, .singlePage__in h6.wp-block-heading::before {
    width: 8px;
    height: 8px;
    top: 9px;
  }
  .singlePage__in blockquote {
    padding: 40px 3.8461538462% 20px;
    margin: 40px 0 30px;
    box-sizing: border-box;
    font-style: italic;
    color: #464646;
    border: 2px solid #e7edf8;
  }
  .singlePage__in blockquote::before {
    font-size: 4rem;
    width: 45px;
    height: 45px;
    line-height: 1.5;
  }
  .singlePage__in blockquote h1 {
    font-size: 1.5rem;
  }
  .singlePage__in blockquote h2 {
    font-size: 1.5rem;
  }
  .singlePage__in blockquote h3 {
    font-size: 1.5rem;
  }
  .singlePage__in blockquote h4 {
    font-size: 1.5rem;
  }
  .singlePage__in blockquote h5 {
    font-size: 1.5rem;
  }
  .singlePage__in blockquote h6 {
    font-size: 1.5rem;
  }
  .singlePage__in img.alignleft {
    float: none;
    display: block;
    margin: 15px auto 20px;
  }
  .singlePage__in img.alignright {
    float: none;
    display: block;
    margin: 15px auto 20px;
  }
  .singlePage__in ul:not([class]) {
    margin: 20px 0;
    padding: 25px 6.4102564103%;
  }
  .singlePage__in ul:not([class]) li {
    font-size: 1.4rem;
    line-height: 1.71428;
    padding-left: 14px;
  }
  .singlePage__in ul:not([class]) li::before {
    width: 6px;
    height: 6px;
    top: 8px;
  }
  .singlePage__in ol:not([class]) {
    margin: 20px 0;
    padding: 25px 3.8461538462%;
  }
  .singlePage__in ol:not([class]) li {
    font-size: 1.4rem;
    line-height: 1.71428;
  }
  .singlePage__in ol:not([class]) li::before {
    font-size: 1.4rem;
  }
}
.singlePage__in iframe {
  width: 100%;
}
.singlePage__in > h1:first-child {
  margin-top: 0 !important;
}
.singlePage__in > h2:first-child {
  margin-top: 0 !important;
}
.singlePage__in > h3:first-child {
  margin-top: 0 !important;
}
.singlePage__in > h4:first-child {
  margin-top: 0 !important;
}
.singlePage__in > h5:first-child {
  margin-top: 0 !important;
}
.singlePage__in > h6:first-child {
  margin-top: 0 !important;
}
@media screen and (min-width: 767px) and (max-width: 1480px) {
  .singlePage__in {
    padding: 70px 4.7297297297vw 40px;
  }
}
.singlePage .articleMv {
  margin: 40px auto;
  text-align: center;
  overflow: hidden;
}
.singlePage .articleMv img {
  border-radius: 15px;
}
.singlePage .date {
  display: flex;
  justify-content: flex-end;
}
.singlePage .date * {
  font-size: 1.4rem !important;
  color: #7c7c7c;
}
.singlePage .date * + * {
  margin: 0 0 0 1rem !important;
}
.singlePage .tocBox {
  margin: 40px auto 100px;
}
.singlePage .tocBox__inner {
  background: #fff;
  border: 1px solid #DFDFDF;
  padding: 34px 3.7837837838vw;
}
.singlePage .tocBox__ttl {
  font-size: 2.9rem;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 24px;
  margin-left: 32px;
}
.singlePage .tocBox__ttl::before {
  content: "";
  width: 8px;
  height: 8px;
  display: inline-block;
  background: #4673c6;
  border-radius: 50px;
  vertical-align: middle;
  margin: -5px 11px 0 0;
}
.singlePage .tocBox .tocList {
  counter-reset: tocNum;
}
.singlePage .tocBox .tocList__item {
  position: relative;
  border-bottom: 1px dashed #D4D4D4;
}
.singlePage .tocBox .tocList__item::before {
  counter-increment: tocNum;
  content: counter(tocNum) ".";
  font-size: 1.6rem;
  font-weight: 400;
  color: #3a67bb;
  display: inline-block;
  position: absolute;
  top: 1px;
  left: 32px;
}
.singlePage .tocBox .tocList__item a {
  font-size: 1.6rem;
  font-weight: 400;
  display: inline-block;
  padding: 0 0 10px 60px;
}
@media screen and (min-width: 767px) {
  .singlePage .tocBox .tocList__item a:hover {
    text-decoration: underline;
    color: #4673c6;
  }
}
.singlePage .tocBox .tocList__item + .tocList__item {
  margin-top: 5px;
}
.singlePage .tocBox .tocList__item .tocList {
  counter-reset: tocChildNum;
  padding-left: 26px;
}
.singlePage .tocBox .tocList__item .tocList__item::before {
  counter-increment: tocChildNum;
  content: counter(tocNum) " - " counter(tocChildNum) ".";
}
.singlePage .tocBox .tocList__item .tocList__item a {
  padding-left: 36px;
}
.singlePage .tocBox .tocList__item .tocList__item + .tocList__item {
  margin-top: 5px;
}
.singlePage .tocBox .tocMore {
  font-size: 1.5rem;
  font-weight: 500;
  background: #ffce08;
  text-align: center;
  margin-top: 20px;
  padding: 8px 20px;
  display: inline-block;
  text-align: center;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
}
@media screen and (min-width: 767px) {
  .singlePage .tocBox .tocMore:hover {
    color: #fff;
    background: #4673c6;
  }
}
.singlePage .tocBox:not(.open) .tocBox__inner > .tocList > .tocList__item:nth-child(n+4) {
  display: none;
}
.singlePage .tocBox:not(.open) .tocMore::after {
  transform: rotate(0deg);
}
@media screen and (min-width: 767px) {
  .singlePage .tocBox:not(.open) .tocMore:hover::after {
    transform: translateY(2px) rotate(90deg);
  }
}
@media screen and (min-width: 0) and (max-width: 767px) {
  .singlePage {
    /*---------------------
      記事タイトル
    ---------------------*/
    /*---------------------
      メインビジュアル
    ---------------------*/
    /*---------------------
      目次
    ---------------------*/
  }
  .singlePage .articleTtl {
    font-size: 2.2rem;
    line-height: 1.5;
    padding: 0 3.8461538462%;
  }
  .singlePage .articleMv {
    margin: 20px auto;
    padding: 0 3.8461538462%;
  }
  .singlePage .date {
    justify-content: flex-start;
  }
  .singlePage .date * {
    font-size: 1.1rem !important;
  }
  .singlePage .tocBox {
    margin: 30px auto;
    padding: 0 3.8461538462%;
  }
  .singlePage .tocBox__inner {
    padding: 20px 3.8461538462%;
  }
  .singlePage .tocBox__ttl {
    font-size: 1.5rem;
    line-height: 1;
    margin-bottom: 20px;
  }
  .singlePage .tocBox .tocList__item::before {
    top: 0;
  }
  .singlePage .tocBox .tocMore {
    font-size: 1.4rem;
  }
}

/* 記事詳細ページ */
.articleDetail {
  padding: 50px 3%;
  background: #F9F9F9;
  /*---------------------
    メインビジュアル
  ---------------------*/
  /*---------------------
    目次
  ---------------------*/
}
.articleDetail .articleMv {
  margin: 40px auto;
  text-align: center;
  overflow: hidden;
}
.articleDetail .articleMv img {
  border-radius: 15px;
}
.articleDetail .date {
  display: flex;
  justify-content: flex-end;
}
.articleDetail .date * {
  font-size: 1.4rem !important;
  color: #7c7c7c;
}
.articleDetail .date * + * {
  margin: 0 0 0 1rem !important;
}
.articleDetail .tocBox {
  margin: 40px auto 100px;
}
.articleDetail .tocBox__inner {
  background: #fff;
  border: 1px solid #DFDFDF;
  padding: 34px 3.7837837838vw;
}
.articleDetail .tocBox__ttl {
  font-size: 2.9rem;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 24px;
  margin-left: 32px;
}
.articleDetail .tocBox__ttl::before {
  content: "";
  width: 8px;
  height: 8px;
  display: inline-block;
  background: #4673c6;
  border-radius: 50px;
  vertical-align: middle;
  margin: -5px 11px 0 0;
}
.articleDetail .tocBox .tocList {
  counter-reset: tocNum;
}
.articleDetail .tocBox .tocList__item {
  position: relative;
  border-bottom: 1px dashed #D4D4D4;
}
.articleDetail .tocBox .tocList__item::before {
  counter-increment: tocNum;
  content: counter(tocNum) ".";
  font-size: 1.6rem;
  font-weight: 400;
  color: #3a67bb;
  display: inline-block;
  position: absolute;
  top: 1px;
  left: 32px;
}
.articleDetail .tocBox .tocList__item a {
  font-size: 1.6rem;
  font-weight: 400;
  display: inline-block;
  padding: 0 0 10px 60px;
}
@media screen and (min-width: 767px) {
  .articleDetail .tocBox .tocList__item a:hover {
    text-decoration: underline;
    color: #4673c6;
  }
}
.articleDetail .tocBox .tocList__item + .tocList__item {
  margin-top: 5px;
}
.articleDetail .tocBox .tocList__item .tocList {
  counter-reset: tocChildNum;
  padding-left: 26px;
}
.articleDetail .tocBox .tocList__item .tocList__item::before {
  counter-increment: tocChildNum;
  content: counter(tocNum) " - " counter(tocChildNum) ".";
}
.articleDetail .tocBox .tocList__item .tocList__item a {
  padding-left: 36px;
}
.articleDetail .tocBox .tocList__item .tocList__item + .tocList__item {
  margin-top: 5px;
}
.articleDetail .tocBox .tocMore {
  font-size: 1.5rem;
  font-weight: 500;
  background: #ffce08;
  text-align: center;
  margin-top: 20px;
  padding: 8px 20px;
  display: inline-block;
  text-align: center;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
}
@media screen and (min-width: 767px) {
  .articleDetail .tocBox .tocMore:hover {
    color: #fff;
    background: #4673c6;
  }
}
.articleDetail .tocBox:not(.open) .tocBox__inner > .tocList > .tocList__item:nth-child(n+4) {
  display: none;
}
.articleDetail .tocBox:not(.open) .tocMore::after {
  transform: rotate(0deg);
}
@media screen and (min-width: 767px) {
  .articleDetail .tocBox:not(.open) .tocMore:hover::after {
    transform: translateY(2px) rotate(90deg);
  }
}
@media screen and (min-width: 0) and (max-width: 767px) {
  .articleDetail {
    /*---------------------
      記事タイトル
    ---------------------*/
    /*---------------------
      メインビジュアル
    ---------------------*/
    /*---------------------
      目次
    ---------------------*/
  }
  .articleDetail .articleTtl {
    font-size: 2.2rem;
    line-height: 1.5;
    padding: 0 3.8461538462%;
  }
  .articleDetail .articleMv {
    margin: 20px auto;
    padding: 0 3.8461538462%;
  }
  .articleDetail .date {
    justify-content: flex-start;
  }
  .articleDetail .date * {
    font-size: 1.1rem !important;
  }
  .articleDetail .tocBox {
    margin: 30px auto;
    padding: 0 3.8461538462%;
  }
  .articleDetail .tocBox__inner {
    padding: 20px 3.8461538462%;
  }
  .articleDetail .tocBox__ttl {
    font-size: 1.5rem;
    line-height: 1;
    margin-bottom: 20px;
  }
  .articleDetail .tocBox .tocList__item::before {
    top: 0;
  }
  .articleDetail .tocBox .tocMore {
    font-size: 1.4rem;
  }
}
.articleDetail__in {
  padding: 70px 70px 40px;
  background: #fff;
  border-radius: 20px;
  max-width: 1180px;
  margin: 0 auto;
}
.articleDetail__in > h1:first-child {
  margin-top: 0 !important;
}
.articleDetail__in > h2:first-child {
  margin-top: 0 !important;
}
.articleDetail__in > h3:first-child {
  margin-top: 0 !important;
}
.articleDetail__in > h4:first-child {
  margin-top: 0 !important;
}
.articleDetail__in > h5:first-child {
  margin-top: 0 !important;
}
.articleDetail__in > h6:first-child {
  margin-top: 0 !important;
}
.articleDetail__in .tocBox + * {
  margin-top: 0 !important;
}
.articleDetail .articleCont h1, .articleDetail .articleCont h2, .articleDetail .articleCont h3, .articleDetail .articleCont h4, .articleDetail .articleCont h5, .articleDetail .articleCont h6 {
  clear: both;
}
.articleDetail .articleCont p:not([class]) {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2;
}
.articleDetail .articleCont p:not([class]) + p {
  margin-top: 20px;
}
.articleDetail .articleCont h1:not([class]), .articleDetail .articleCont h1.wp-block-heading {
  font-size: 4.2rem;
  font-weight: 700;
  line-height: 1.33333;
  margin-top: 100px;
  margin-bottom: 40px;
  padding-left: 25px;
  position: relative;
}
.articleDetail .articleCont h1:not([class])::before, .articleDetail .articleCont h1.wp-block-heading::before {
  content: "";
  width: 12px;
  height: 12px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50px;
  background: #4673c6;
  position: absolute;
  top: 25px;
  left: 0;
}
.articleDetail .articleCont h2:not([class]), .articleDetail .articleCont h2.wp-block-heading {
  font-size: 2.4rem;
  font-weight: 700;
  color: #fff;
  line-height: 1.66666;
  margin-top: 100px;
  margin-bottom: 40px;
  padding: 28px 30px;
  background: #4673c6;
}
@media screen and (min-width: 767px) and (max-width: 1480px) {
  .articleDetail .articleCont h2:not([class]), .articleDetail .articleCont h2.wp-block-heading {
    padding: 1.8918918919vw 2.027027027vw;
  }
}
.articleDetail .articleCont h3:not([class]), .articleDetail .articleCont h3.wp-block-heading {
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.5;
  margin-top: 60px;
  margin-bottom: 30px;
  padding-bottom: 20px;
  border-bottom: 3px solid #cbd5e8;
  position: relative;
}
.articleDetail .articleCont h3:not([class])::after, .articleDetail .articleCont h3.wp-block-heading::after {
  content: "";
  width: 9em;
  height: 3px;
  display: inline-block;
  background: #4673c6;
  position: absolute;
  left: 0;
  bottom: -3px;
}
.articleDetail .articleCont h4:not([class]), .articleDetail .articleCont h4.wp-block-heading {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.5;
  margin-top: 60px;
  margin-bottom: 20px;
  padding-left: 29px;
  position: relative;
}
.articleDetail .articleCont h4:not([class])::before, .articleDetail .articleCont h4.wp-block-heading::before {
  content: "";
  width: 8px;
  height: 8px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50px;
  background: #4673c6;
  position: absolute;
  top: 10px;
  left: 8px;
}
.articleDetail .articleCont h5:not([class]), .articleDetail .articleCont h5.wp-block-heading {
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.44444;
  color: #4673c6;
  margin-top: 40px;
  margin-bottom: 20px;
  padding: 10px 15px;
  border-left: 4px solid #4673c6;
  background: #F9F9F9;
}
.articleDetail .articleCont h6:not([class]), .articleDetail .articleCont h6.wp-block-heading {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.77777;
  color: #4673c6;
  margin-top: 40px;
  margin-bottom: 20px;
  padding-left: 20px;
  position: relative;
}
.articleDetail .articleCont h6:not([class])::before, .articleDetail .articleCont h6.wp-block-heading::before {
  content: "";
  width: 10px;
  height: 10px;
  display: inline-block;
  background: #4673c6;
  position: absolute;
  top: 12px;
  left: 0;
}
.articleDetail .articleCont blockquote {
  position: relative;
  padding: 50px 20px 20px;
  margin: 50px 0 30px;
  box-sizing: border-box;
  font-style: italic;
  color: #464646;
  border: 2px solid #e7edf8;
}
.articleDetail .articleCont blockquote::before {
  content: "“";
  font-size: 5rem;
  font-weight: 700;
  color: #4673c6;
  display: inline-block;
  font-style: normal;
  width: 50px;
  height: 50px;
  line-height: 1.3;
  text-align: center;
  background: #e7edf8;
  border-radius: 50px;
  position: absolute;
  top: -25px;
  left: 15px;
}
.articleDetail .articleCont blockquote h1 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.articleDetail .articleCont blockquote h2 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.articleDetail .articleCont blockquote h3 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.articleDetail .articleCont blockquote h4 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.articleDetail .articleCont blockquote h5 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.articleDetail .articleCont blockquote h6 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.articleDetail .articleCont blockquote p {
  font-style: normal;
}
.articleDetail .articleCont blockquote cite {
  display: block;
  text-align: right;
  line-height: 1;
  color: #888888;
  font-size: 0.9em;
  margin: 10px 0 0;
}
.articleDetail .articleCont .btnWrap {
  clear: both;
}
.articleDetail .articleCont img:not([class]), .articleDetail .articleCont.wp-block-heading {
  display: block;
  margin: 15px auto 20px;
}
.articleDetail .articleCont img.alignleft {
  float: left;
  margin: 0 30px 30px 0;
}
.articleDetail .articleCont img.alignright {
  float: right;
  margin: 0 0 30px 30px;
}
.articleDetail .articleCont ul:not([class]) {
  margin: 30px 0;
  padding: 30px;
  background: #FFF;
  border: 2px solid #cbd5e8;
  clear: both;
}
@media screen and (min-width: 767px) and (max-width: 1480px) {
  .articleDetail .articleCont ul:not([class]) {
    padding: 2.027027027vw;
  }
}
.articleDetail .articleCont ul:not([class]) li {
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 2;
  padding-left: 18px;
  position: relative;
}
.articleDetail .articleCont ul:not([class]) li::before {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50px;
  background: #4673c6;
  display: inline-block;
  position: absolute;
  top: 13px;
  left: 0;
}
.articleDetail .articleCont ul:not([class]) li + li {
  margin-top: 10px;
}
.articleDetail .articleCont ul:not([class]) h1::before {
  content: none;
}
.articleDetail .articleCont ul:not([class]) h2::before {
  content: none;
}
.articleDetail .articleCont ul:not([class]) h3::before {
  content: none;
}
.articleDetail .articleCont ul:not([class]) h4::before {
  content: none;
}
.articleDetail .articleCont ul:not([class]) h5::before {
  content: none;
}
.articleDetail .articleCont ul:not([class]) h6::before {
  content: none;
}
.articleDetail .articleCont ol:not([class]) {
  margin: 30px 0;
  padding: 30px;
  background: #e7edf8;
  counter-reset: listNum;
  clear: both;
}
.articleDetail .articleCont ol:not([class]) li {
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 2;
  padding-left: 38px;
  position: relative;
}
.articleDetail .articleCont ol:not([class]) li::before {
  counter-increment: listNum;
  content: counter(listNum);
  font-size: 1.6rem;
  font-weight: 700;
  color: #4673c6;
  width: 28px;
  text-align: center;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}
.articleDetail .articleCont ol:not([class]) li + li {
  margin-top: 10px;
}
.articleDetail .articleCont ol:not([class]) h1::before {
  content: none;
}
.articleDetail .articleCont ol:not([class]) h2::before {
  content: none;
}
.articleDetail .articleCont ol:not([class]) h3::before {
  content: none;
}
.articleDetail .articleCont ol:not([class]) h4::before {
  content: none;
}
.articleDetail .articleCont ol:not([class]) h5::before {
  content: none;
}
.articleDetail .articleCont ol:not([class]) h6::before {
  content: none;
}
@media screen and (min-width: 0) and (max-width: 767px) {
  .articleDetail .articleCont p:not([class]) {
    font-size: 1.4rem;
    line-height: 1.85714;
  }
  .articleDetail .articleCont p:not([class]) + p {
    margin-top: 10px;
  }
  .articleDetail .articleCont h1:not([class]), .articleDetail .articleCont h1.wp-block-heading {
    font-size: 2.4rem;
    margin-top: 54px;
    margin-bottom: 20px;
    padding-left: 15px;
  }
  .articleDetail .articleCont h1:not([class])::before, .articleDetail .articleCont h1.wp-block-heading::before {
    width: 8px;
    height: 8px;
    top: 13px;
  }
  .articleDetail .articleCont h2:not([class]), .articleDetail .articleCont h2.wp-block-heading {
    font-size: 1.8rem;
    line-height: 1.38888;
    margin-top: 54px;
    margin-bottom: 20px;
    padding: 15px 7.6923076923%;
    background: #4673c6;
  }
  .articleDetail .articleCont h3:not([class]), .articleDetail .articleCont h3.wp-block-heading {
    font-size: 1.6rem;
    line-height: 1.625;
    margin-top: 40px;
    margin-bottom: 20px;
    padding-bottom: 14px;
  }
  .articleDetail .articleCont h3:not([class])::after, .articleDetail .articleCont h3.wp-block-heading::after {
    width: 6.5em;
  }
  .articleDetail .articleCont h4:not([class]), .articleDetail .articleCont h4.wp-block-heading {
    font-size: 1.6rem;
    line-height: 1.625;
    margin-top: 40px;
    padding-left: 20px;
  }
  .articleDetail .articleCont h4:not([class])::before, .articleDetail .articleCont h4.wp-block-heading::before {
    top: 10px;
    left: 5px;
  }
  .articleDetail .articleCont h5:not([class]), .articleDetail .articleCont h5.wp-block-heading {
    font-size: 1.5rem;
    line-height: 1.73333;
    padding: 10px 12px;
  }
  .articleDetail .articleCont h6:not([class]), .articleDetail .articleCont h6.wp-block-heading {
    font-size: 1.5rem;
    line-height: 1.73333;
    padding-left: 18px;
  }
  .articleDetail .articleCont h6:not([class])::before, .articleDetail .articleCont h6.wp-block-heading::before {
    width: 8px;
    height: 8px;
    top: 9px;
  }
  .articleDetail .articleCont blockquote {
    padding: 40px 3.8461538462% 20px;
    margin: 40px 0 30px;
    box-sizing: border-box;
    font-style: italic;
    color: #464646;
    border: 2px solid #e7edf8;
  }
  .articleDetail .articleCont blockquote::before {
    font-size: 4rem;
    width: 45px;
    height: 45px;
    line-height: 1.5;
  }
  .articleDetail .articleCont blockquote h1 {
    font-size: 1.5rem;
  }
  .articleDetail .articleCont blockquote h2 {
    font-size: 1.5rem;
  }
  .articleDetail .articleCont blockquote h3 {
    font-size: 1.5rem;
  }
  .articleDetail .articleCont blockquote h4 {
    font-size: 1.5rem;
  }
  .articleDetail .articleCont blockquote h5 {
    font-size: 1.5rem;
  }
  .articleDetail .articleCont blockquote h6 {
    font-size: 1.5rem;
  }
  .articleDetail .articleCont img.alignleft {
    float: none;
    display: block;
    margin: 15px auto 20px;
  }
  .articleDetail .articleCont img.alignright {
    float: none;
    display: block;
    margin: 15px auto 20px;
  }
  .articleDetail .articleCont ul:not([class]) {
    margin: 20px 0;
    padding: 25px 6.4102564103%;
  }
  .articleDetail .articleCont ul:not([class]) li {
    font-size: 1.4rem;
    line-height: 1.71428;
    padding-left: 14px;
  }
  .articleDetail .articleCont ul:not([class]) li::before {
    width: 6px;
    height: 6px;
    top: 8px;
  }
  .articleDetail .articleCont ol:not([class]) {
    margin: 20px 0;
    padding: 25px 3.8461538462%;
  }
  .articleDetail .articleCont ol:not([class]) li {
    font-size: 1.4rem;
    line-height: 1.71428;
  }
  .articleDetail .articleCont ol:not([class]) li::before {
    font-size: 1.4rem;
  }
}
.articleDetail .articleCont iframe {
  width: 100%;
}

/* 関連記事 */
.relatedBox {
  padding: 100px 1% 50px;
}
.relatedBox__inner {
  max-width: 1180px;
  margin: 0 auto;
}
.relatedBox__ttl {
  font-size: 4.5rem;
  font-weight: 700;
  line-height: 1.5;
}
.relatedBox__ttl::before {
  content: "";
  width: 12px;
  height: 12px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50px;
  background: #4673c6;
  margin: -5px 15px 0 0;
}
.relatedBox__text {
  font-size: 1.6rem;
  font-weight: 400;
  margin-top: 7px;
}
.relatedBox .relatedList {
  margin-top: 30px;
}

.relatedList {
  display: flex;
  flex-wrap: wrap;
}
.relatedList__item {
  width: calc((100% - 100px) / 3);
  margin-bottom: 50px;
}
.relatedList__item:not(:nth-child(3n+1)) {
  margin-left: 50px;
}
@media screen and (min-width: 767px) {
  .relatedList__item:hover .textWrap__ttl {
    text-decoration: underline !important;
  }
}
@media screen and (min-width: 767px) and (max-width: 1480px) {
  .relatedList__item {
    width: calc((100% - 6.7567567568vw) / 3);
    margin-bottom: 3.3783783784vw;
  }
  .relatedList__item:not(:nth-child(3n+1)) {
    margin-left: 3.3783783784vw;
  }
}
.relatedList__item .itemWrap {
  display: flex;
  justify-content: flex-end;
  flex-direction: row-reverse;
  flex-wrap: wrap;
}
.relatedList__item .itemWrap .thumb {
  width: 100%;
  margin-bottom: 10px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}
.relatedList__item .itemWrap .thumb img {
  margin: 0 auto;
}
.relatedList__item .itemWrap .thumb .label {
  font-size: 1.2rem;
  font-weight: 400;
  color: #fff;
  display: inline-block;
  background: rgba(34, 34, 34, 0.6);
  padding: 7px 16px;
  position: absolute;
  bottom: 0;
  left: 0;
}
.relatedList__item .itemWrap .textWrap {
  width: 100%;
}
.relatedList__item .itemWrap .textWrap__date {
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 14px;
}
.relatedList__item .itemWrap .textWrap__ttl {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.625;
  display: block;
  text-decoration: none;
}
.relatedList__item .itemWrap .textWrap__text {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2;
  margin-top: 10px;
}

@media screen and (max-width: 767px) {
  /* singlePage */
  .singlePage {
    padding: 50px 3.8461538462%;
    margin-top: 20px;
  }
  .singlePage__in {
    padding: 30px 3.8461538462% 42px;
    overflow: hidden;
  }
  /* 記事詳細ページ */
  .articleDetail {
    padding: 50px 3.8461538462%;
    margin-top: 20px;
  }
  .articleDetail__in {
    padding: 30px 3.8461538462% 42px;
    overflow: hidden;
  }
  /* 関連記事 */
  .relatedBox {
    padding: 40px 10.2564102564%;
  }
  .relatedBox__ttl {
    font-size: 2.4rem;
    line-height: 1.083333;
  }
  .relatedBox__ttl::before {
    width: 6px;
    height: 6px;
    margin: -5px 8px 0 0;
  }
  .relatedBox__text {
    font-size: 1.4rem;
    margin-top: 10px;
  }
  .relatedList {
    display: block;
  }
  .relatedList__item {
    width: 100%;
    margin-bottom: 30px;
  }
  .relatedList__item:not(:nth-child(3n+1)) {
    margin-left: 0;
  }
  .relatedList__item .itemWrap {
    display: block;
  }
  .relatedList__item .itemWrap .thumb {
    margin-bottom: 12px;
    border-radius: 5px;
  }
  .relatedList__item .itemWrap .thumb .label {
    font-size: 1rem;
  }
  .relatedList__item .itemWrap .textWrap__date {
    margin-bottom: 12px;
  }
  .relatedList__item .itemWrap .textWrap__ttl {
    font-size: 1.4rem;
    line-height: 1.71428;
  }
  .relatedList__item .itemWrap .textWrap__text {
    line-height: 1.66666;
    display: none;
  }
  /* 記事一覧ページ */
  .articleList__item {
    border-radius: 10px;
  }
  .articleList__item .itemWrap {
    padding: 20px 4.358974359% 20px 6.9230769231%;
    display: block;
  }
  .articleList__item .itemWrap .thumb {
    width: 100%;
    max-width: initial;
    margin: 0 0 15px;
    text-align: center;
  }
  .articleList__item .itemWrap .thumb + .textWrap {
    width: 100%;
  }
  .articleList__item .itemWrap .textWrap__ttl {
    font-size: 1.6rem;
  }
  .articleList__item .itemWrap .textWrap__ttl::before {
    width: 6px;
    height: 6px;
    top: 12px;
    left: -4.358974359%;
  }
  .articleList__item .itemWrap .textWrap__text {
    font-size: 1.4rem;
    line-height: 1.66666;
    margin-top: 10px;
    display: none;
  }
  .articleList__item + .articleList__item {
    margin-top: 20px;
  }
  .articleListWrap {
    padding: 50px 3.8461538462%;
    margin-top: 20px;
  }
  .articleListWrap__in {
    padding: 30px 3.8461538462% 42px;
    overflow: hidden;
  }
  .articleListWrap__in .pager + h1 {
    margin-top: 50px;
  }
  .articleListWrap__in .pager + h2 {
    margin-top: 50px;
  }
  .articleListWrap__in .pager + h3 {
    margin-top: 50px;
  }
  .articleListWrap__in .pager + h4 {
    margin-top: 50px;
  }
  .articleListWrap__in .pager + h5 {
    margin-top: 50px;
  }
  .articleListWrap__in .pager + h6 {
    margin-top: 50px;
  }
}
/*=================================
  お問い合わせ
=================================*/
.formBox h1 {
  margin-top: 0 !important;
  margin-bottom: 80px !important;
}
.formBox h2 {
  margin-top: 0 !important;
  margin-bottom: 80px !important;
}
.formBox h3 {
  margin-top: 0 !important;
  margin-bottom: 80px !important;
}
.formBox h4 {
  margin-top: 0 !important;
  margin-bottom: 80px !important;
}
.formBox h5 {
  margin-top: 0 !important;
  margin-bottom: 80px !important;
}
.formBox h6 {
  margin-top: 0 !important;
  margin-bottom: 80px !important;
}
.formBox .step {
  display: inline-block;
  counter-reset: stepNum;
  margin-bottom: 55px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
.formBox .step::before {
  content: "";
  width: calc(100% - 5.5rem);
  height: 1px;
  display: inline-block;
  background: #cbd5e8;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.formBox .step__item {
  font-size: 1.8rem;
  font-weight: bold;
  text-align: center;
  padding-top: 26px;
  display: inline-block;
  position: relative;
}
.formBox .step__item::before {
  counter-increment: stepNum;
  content: "STEP0" counter(stepNum);
  display: block;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.625;
  color: #4673c6;
  margin-bottom: 10px;
}
.formBox .step__item::after {
  content: "";
  width: 15px;
  height: 15px;
  display: inline-block;
  border-radius: 50px;
  background: #cbd5e8;
  position: absolute;
  top: -8px;
  left: 50%;
  transform: translateX(-50%);
}
.formBox .step__item.active::after {
  background: #4673c6;
}
.formBox .step__item + .step__item {
  margin-left: 59px;
}
.formBox .btnWrap {
  margin-top: 40px;
}
.formBox .btn--form {
  display: block;
  text-align: center;
}
.formBox .btn--form:not(.back) input {
  font-size: 2rem;
  font-weight: bold;
  color: #fff;
  text-decoration: none;
  padding: 30px 68px 28px;
  border-radius: 5px;
  background: #4673c6;
  box-shadow: 0px 5px 0 0px #2a4983;
  cursor: pointer;
}
@media screen and (min-width: 767px) {
  .formBox .btn--form:not(.back):hover input {
    box-shadow: none !important;
    transform: translateY(5px);
  }
}
.formBox .btn--form.back input {
  font-size: 1.4rem;
  color: #999;
  cursor: pointer;
}

/* =================================
  お問い合わせフォーム
================================= */
.text--security {
  font-size: 1.6rem;
  margin-bottom: 30px;
  text-align: center;
}

.contactForm table {
  width: 100%;
}
.contactForm th {
  font-size: 1.6rem;
  padding: 15px 65px 15px 20px;
  text-align: left;
  width: 240px;
  position: relative;
}
.contactForm th .sub {
  font-size: 1.4rem;
  display: block;
}
.contactForm th span:not(.sub) {
  font-size: 1.2rem;
  font-weight: normal;
  letter-spacing: 0.04em;
  line-height: 1;
  color: #fff;
  background: #cbd5e8;
  padding: 5px 12px;
  position: absolute;
  top: 20px;
  right: 10px;
}
.contactForm th .required {
  background: #FC2828 !important;
}
.contactForm td {
  font-size: 1.6rem;
  padding: 17px 15px 17px 20px;
  width: calc(100% - 240px);
  text-align: left;
}
.contactForm td p {
  line-height: 1.65;
}
.contactForm td * {
  font-size: 1.6rem;
}
.contactForm td input, .contactForm td textarea, .contactForm td select {
  background: #fff;
  border: 2px solid #cbd5e8;
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
}
.contactForm td textarea {
  min-height: 170px;
}
.contactForm td .error {
  font-size: 1.4rem;
  color: #FC2828;
  margin: 10px 0 0;
  display: inline-block;
}
.contactForm .confirmingTxt {
  text-align: center;
  margin: 40px 0;
}
.contactForm .confirmingTxt p {
  font-size: 1.6rem;
}
.contactForm .confirmingTxt .checkText {
  font-size: 1.8rem;
  margin-top: 40px;
  display: inline-block;
}
.contactForm .confirmingTxt .checkText input {
  margin-right: 10px;
}
.contactForm .confirmingTxt input {
  -webkit-appearance: checkbox;
}
.contactForm .confirmingTxt a {
  font-weight: bold;
  text-decoration: none;
}
.contactForm .confirmingTxt a:hover {
  text-decoration: underline;
}

.consultForm__item {
  display: flex;
  align-items: center;
  background: #e7edf8;
  padding: 38px 20px;
  border: 1px solid #cbd5e8;
}
.consultForm__item + .consultForm__item {
  margin-top: 20px;
}
.consultForm__ttl {
  font-size: 1.6rem;
  font-weight: bold;
  color: #4673c6;
  width: 230px;
  padding-right: 55px;
  position: relative;
}
.consultForm__ttl .sub {
  font-size: 1.4rem;
  display: block;
}
.consultForm__ttl span:not(.sub) {
  font-size: 1.2rem;
  font-weight: normal;
  letter-spacing: 0.04em;
  line-height: 1;
  color: #fff;
  background: #cbd5e8;
  padding: 5px 12px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
.consultForm__ttl .required {
  background: #FC2828 !important;
}
.consultForm__list {
  font-size: 1.6rem;
  width: calc(93% - 230px);
  padding-left: 27px;
}
.consultForm__list p {
  line-height: 1.65;
}
.consultForm__list * {
  font-size: 1.6rem;
}
.consultForm__list input:not([type=radio]):not([type=checkbox]), .consultForm__list textarea, .consultForm__list select {
  background: #fff;
  border: 2px solid #cbd5e8;
  padding: 10px;
}
.consultForm__list input:not([type=radio]):not([type=checkbox]):not([class=widthNormal]), .consultForm__list textarea, .consultForm__list select {
  width: 100%;
  box-sizing: border-box;
}
.consultForm__list .mwform-radio-field {
  display: inline-block;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-right: 3rem;
}
.consultForm__list .mwform-radio-field label {
  display: inline-block;
}
.consultForm__list input[type=radio] + span {
  padding-left: 2.8rem;
  display: inline-block;
  position: relative;
}
.consultForm__list input[type=radio] + span::before {
  content: "";
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  margin: auto;
  border-radius: 50%;
  border: solid 2px #cbd5e8;
  background-color: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}
.consultForm__list input[type=radio]:checked + span::after {
  content: "";
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin: auto 0;
  background-color: #4673c6;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 4px;
}
.consultForm__list .mwform-checkbox-field {
  display: inline-block;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-right: 3rem;
}
.consultForm__list .mwform-checkbox-field label {
  display: inline-block;
}
.consultForm__list input[type=checkbox] + span {
  padding-left: 2.8rem;
  display: inline-block;
  position: relative;
}
.consultForm__list input[type=checkbox] + span::before {
  content: "";
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  margin: auto;
  border-radius: 4px;
  border: solid 2px #cbd5e8;
  background-color: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}
.consultForm__list input[type=checkbox]:checked + span::after {
  content: "";
  width: 11px;
  height: 7px;
  display: inline-block;
  background: url(../img/common/icon/icon_check.png) no-repeat center center;
  background-size: contain;
  position: absolute;
  top: 10px;
  left: 5px;
}
.consultForm__list textarea {
  min-height: 170px;
}
.consultForm__list .error {
  font-size: 1.4rem;
  color: #FC2828;
  margin: 10px 0 0;
  display: block;
}
.consultForm__list .yoyaku {
  display: flex;
  align-items: center;
}
.consultForm__list .yoyaku .rank {
  font-weight: bold;
  color: #4673c6;
  width: 85px;
}
.consultForm__list .yoyaku .yoyakuBox {
  width: calc(100% - 85px);
  display: flex;
}
.consultForm__list .yoyaku .yoyakudate {
  width: calc((100% - 15px) / 2) !important;
}
.consultForm__list .yoyaku .yoyakujikan {
  width: calc((100% - 15px) / 2) !important;
  margin-left: 15px;
}
.consultForm__list .yoyaku + .yoyaku {
  margin-top: 15px;
}
.consultForm .confirmingTxt {
  text-align: center;
  margin: 37px 0;
}
.consultForm .confirmingTxt p {
  font-size: 1.4rem;
}
.consultForm .confirmingTxt input {
  -webkit-appearance: checkbox;
}
.consultForm .confirmingTxt a {
  text-decoration: underline;
}
.consultForm .confirmingTxt a:hover {
  color: #4673c6;
}
.consultForm + .consultForm {
  border-top: 1px dashed #cbd5e8;
  padding-top: 20px;
  margin-top: 20px;
}

.completedForm .completedMessage {
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 1;
  letter-spacing: 0.06em;
  display: inline-block;
  margin: 24px 0 0;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
.completedForm .completedMessage::after {
  content: "";
  width: 100%;
  height: 3px;
  background: #4673c6;
  display: inline-block;
}
.completedForm p {
  font-size: 1.5rem;
  text-align: center;
  margin: 40px 0 0;
}
.completedForm table {
  width: initial;
  margin: 40px auto 45px;
}
.completedForm th, .completedForm td {
  width: auto;
}
.completedForm th {
  font-size: 1.6rem;
  font-weight: bold;
  vertical-align: middle;
  padding: 15px 30px;
}
.completedForm td {
  vertical-align: middle;
  padding: 15px 30px;
}
.completedForm td .tel {
  font-size: 3.4rem;
  font-weight: bold;
  color: #4673c6;
  line-height: 1;
  text-decoration: none;
  margin: 0;
}
@media screen and (min-width: 767px) {
  .completedForm td .tel {
    pointer-events: none;
  }
}
.completedForm td .receptionTime {
  display: flex;
  margin: 10px 0 0;
}
.completedForm td .receptionTime dt {
  font-size: 1.3rem;
  font-weight: bold;
  color: #4673c6;
  width: 5.5em;
}
.completedForm td .receptionTime dd {
  font-size: 1.3rem;
  width: calc(100% - 5.5em);
}

@media screen and (max-width: 767px) {
  /*=================================
    お問い合わせ
  =================================*/
  .formBox h1 {
    margin-bottom: 40px !important;
  }
  .formBox h2 {
    margin-bottom: 40px !important;
  }
  .formBox h3 {
    margin-bottom: 40px !important;
  }
  .formBox h4 {
    margin-bottom: 40px !important;
  }
  .formBox h5 {
    margin-bottom: 40px !important;
  }
  .formBox h6 {
    margin-bottom: 40px !important;
  }
  .formBox .infoBox {
    display: block;
    margin-top: 0;
    overflow: hidden;
  }
  .formBox .infoBox .thumb {
    width: 30%;
    margin-right: 0;
    margin-bottom: 15px;
    float: left;
  }
  .formBox .infoBox .contact {
    width: 100%;
  }
  .formBox .infoBox .contact .infoBoxTtl {
    font-size: 1.5rem;
    line-height: 1.5;
    width: calc(100% - (30% + 15px));
    margin-left: auto;
  }
  .formBox .infoBox .contact .address .info--tel {
    margin-top: 0;
  }
  .formBox .infoBox .contact .address .info--tel .tel {
    width: calc(100% - (30% + 15px));
    margin-left: auto;
    margin-top: 15px;
  }
  .formBox .infoBox .contact .address .info--tel .tel .num {
    font-size: 1.8rem;
    color: #fff;
    background: #4673c6;
    display: block;
    padding: 10px 10px;
    border-radius: 3px;
    text-align: center;
  }
  .formBox .infoBox .contact .address .info--tel .tel .num::before {
    width: 18px;
    height: 18px;
    display: inline-block;
    margin: 0 7px -3px 0;
  }
  .formBox .infoBox .contact .address .info--tel .receptionTime {
    width: 100%;
    font-size: 1.4rem;
    margin-top: 15px;
  }
  .formBox .step {
    margin-bottom: 40px;
  }
  .formBox .step::before {
    width: calc(100% - 5rem);
  }
  .formBox .step__item {
    font-size: 1.6rem;
    padding-top: 20px;
  }
  .formBox .step__item::before {
    font-size: 1.4rem;
    margin-bottom: 5px;
  }
  .formBox .step__item::after {
    width: 13px;
    height: 13px;
    top: -6px;
  }
  .formBox .step__item.active::after {
    background: #4673c6;
  }
  .formBox .step__item + .step__item {
    margin-left: 15.1282051282vw;
  }
  .formBox .btnWrap {
    margin-top: 35px;
  }
  .formBox .btn--form:not(.back) input {
    font-size: 1.6rem;
    padding: 15px 3.5897435897% 13px;
    border-radius: 4px;
    box-shadow: 0px 4px 0 0px #2a4983;
  }
  /* =================================
    お問い合わせフォーム
  ================================= */
  .text--security {
    font-size: 1.4rem;
  }
  .contactForm table {
    display: table;
    white-space: initial;
  }
  .contactForm th {
    font-size: 1.5rem;
    padding: 15px 3.5897435897%;
    width: 100%;
    display: block;
  }
  .contactForm th span:not(.sub) {
    font-size: 1.1rem;
    letter-spacing: 0.4em;
    padding: 4px 4px 4px 8px;
    margin-left: 1.5rem;
    position: static;
    transform: translateY(0);
  }
  .contactForm td {
    padding: 15px 3.5897435897%;
    width: 100%;
    display: block;
  }
  .contactForm td p {
    line-height: 1.5;
  }
  .contactForm td input, .contactForm td textarea, .contactForm td select {
    padding: 12px;
  }
  .contactForm td .error {
    font-size: 1.3rem;
  }
  .contactForm .confirmingTxt {
    margin: 30px 0;
  }
  .contactForm .confirmingTxt p {
    font-size: 1.4rem;
  }
  .contactForm .confirmingTxt .checkText {
    font-size: 1.6rem;
    margin-top: 10px;
  }
  .consultForm__item {
    display: block;
    padding: 10px 3.5897435897% 20px;
  }
  .consultForm__ttl {
    font-size: 1.5rem;
    padding: 15px 0;
    width: 100%;
    display: block;
  }
  .consultForm__ttl .sub {
    font-size: 1.4rem;
    display: inline-block;
  }
  .consultForm__ttl span:not(.sub) {
    font-size: 1.1rem;
    letter-spacing: 0.4em;
    padding: 4px 4px 4px 8px;
    margin-left: 1.5rem;
    position: static;
    transform: translateY(0);
  }
  .consultForm__list {
    width: 100%;
    padding-left: 0;
  }
  .consultForm__list p {
    line-height: 1.5;
  }
  .consultForm__list .mwform-radio-field {
    margin-right: 0.5rem;
  }
  .consultForm__list .mwform-checkbox-field {
    margin-right: 0.5rem;
  }
  .consultForm__list input[type=checkbox]:checked + span::after {
    width: 15px;
    height: 10px;
    top: 6px;
    left: 3px;
  }
  .consultForm__list .error {
    font-size: 1.3rem;
  }
  .consultForm__list .yoyaku {
    flex-wrap: wrap;
  }
  .consultForm__list .yoyaku .rank {
    width: 100%;
    margin-bottom: 10px;
  }
  .consultForm__list .yoyaku .yoyakuBox {
    width: 100%;
    display: block;
  }
  .consultForm__list .yoyaku .yoyakudate {
    width: 100% !important;
  }
  .consultForm__list .yoyaku .yoyakujikan {
    width: 100% !important;
    margin: 10px 0 0;
  }
  .consultForm__list .yoyaku + .yoyaku {
    margin-top: 20px;
  }
  .consultForm .confirmingTxt {
    text-align: center;
    margin: 37px 0;
  }
  .consultForm .confirmingTxt p {
    font-size: 1.4rem;
  }
  .completedForm p {
    margin: 30px 0 0;
  }
  .completedForm table {
    display: table;
    white-space: initial;
    margin: 40px auto 45px;
  }
  .completedForm th, .completedForm td {
    width: auto;
  }
  .completedForm th {
    font-size: 1.4rem;
    padding: 15px 3.5897435897%;
  }
  .completedForm td {
    padding: 15px 3.5897435897%;
  }
  .completedForm td .tel {
    font-size: 3rem;
  }
  .completedForm td .receptionTime {
    text-align: left;
  }
}
/* =================================
  header
================================= */
.home .header {
  background: transparent;
  padding: 33px 3%;
  position: absolute;
  z-index: 10;
}
.home .header .gNavList__item::after {
  background: #ffce08;
}

.header {
  width: 100%;
  padding: 10px 3%;
  background: #7d9dd7;
}
.header__inner {
  display: flex;
  align-items: center;
}
.header__logo {
  width: clamp(184px, 19.1891891892vw, 284px);
}
.header__logo .logo {
  display: inline-block;
  vertical-align: middle;
}
.header__logo .logo a {
  display: block;
}
@media screen and (min-width: 767px) {
  .header__logo .logo a:hover {
    opacity: 0.8;
  }
}
.header__logo .logo img {
  vertical-align: baseline;
}
.header .gNav {
  margin-left: auto;
}
.header .gNavList {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.header .gNavList__item {
  display: inline-block;
  position: relative;
}
.header .gNavList__item:not(:first-child) {
  margin: 0 0 0 35px;
}
@media screen and (min-width: 767px) and (max-width: 1480px) {
  .header .gNavList__item:not(:first-child) {
    margin: 0 0 0 2.3648648649vw;
  }
}
.header .gNavList__item::after {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  background: #4673c6;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  /*アニメーション*/
  transition: transform 0.3s;
  transform: scale(0, 1);
  transform-origin: right top;
}
@media screen and (min-width: 767px) {
  .header .gNavList__item:hover::after {
    transform: scale(1, 1);
    transform-origin: left top;
  }
}
.header .gNavList__item a {
  font-family: "Montserrat", sans-serif;
  font-size: clamp(1.2rem, 1.0135135135vw, 1.5rem);
  font-weight: 600;
  letter-spacing: 0.14em;
  color: #fff;
  padding: 0 5px;
  text-decoration: none;
  display: block;
  position: relative;
  z-index: 2;
}
.header .gNavList__item.current-menu-item a::after {
  transform: scale(1, 1);
  transform-origin: left top;
}

@media screen and (min-width: 767px) {
  .gNavList.fixedNav {
    padding: 12px 3%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    background: rgba(255, 206, 8, 0.8);
    box-shadow: 0 5px 25px rgba(34, 34, 34, 0.05);
    z-index: 999;
    animation: header-show 0.5s ease-in;
  }
  .gNavList.fixedNav .gNavList__item::after {
    background: #fff;
  }
  .gNavList.fixedNav .gNavList__item a:hover {
    color: #ffce08;
  }
}
@media screen and (max-width: 767px) {
  /* =================================
    header
  ================================= */
  .home .header {
    padding: 0;
    top: 0;
    left: 0;
    z-index: 9999;
  }
  .home .header .gNavList__item::after {
    background: transparent;
  }
  .header {
    padding: 0;
    /*バーガーボタン設定*/
  }
  .header__inner {
    display: block;
  }
  .header__logo {
    width: 100%;
    min-height: 60px;
    padding: 13px 0 13px 3.3333333333%;
  }
  .header__logo .logo {
    width: 31.7948717949vw;
    line-height: 1;
  }
  .header .gNav {
    overflow-y: scroll;
    max-height: 90vh;
    width: 100%;
    margin: auto;
    padding: 70px 3.8461538462%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    transform: scale(0.9);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease-in-out, transform 0.3s ease-out, visibility 0.2s ease-out;
  }
  .header .gNavList {
    display: block;
  }
  .header .gNavList__item {
    display: block;
  }
  .header .gNavList__item:not(:first-child) {
    margin: 3.8461538462% 0 0;
  }
  .header .gNavList__item::after {
    content: "";
    width: 10px;
    height: 10px;
    display: inline-block;
    background: transparent;
    top: 50%;
    left: 0;
    transform: scale(1, 1) translateY(-50%);
    border-width: 0 6px 9px 6px;
    border-style: solid;
    border-color: transparent transparent #fff transparent;
  }
  .header .gNavList__item a {
    font-size: 1.8rem;
    color: #fff;
    padding: 10px 5px 10px 6.4102564103%;
    text-decoration: none;
    display: block;
    position: relative;
    z-index: 2;
  }
  .header .gNav.show {
    transform: scale(1);
    visibility: visible;
    opacity: 1;
  }
  .header .gNav.hide {
    transform: scale(0.9);
    opacity: 0;
    visibility: hidden;
  }
  .header .headerNav__cover {
    content: "";
    width: 100%;
    height: 100vh;
    display: block;
    background: #ffce08;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    /*アニメーション*/
    transition: transform 0.3s;
    transform: scale(0, 1);
    transform-origin: right top;
  }
  .header .headerNav__cover.show {
    transform: scale(1, 1);
    transform-origin: left top;
  }
  .header .headerNav__cover.hide {
    transform: scale(0, 1);
    transform-origin: right top;
  }
  .header .burger {
    width: 40px;
    height: 40px;
    background: #ffce08;
    margin: auto;
    border-radius: 50px;
    position: fixed;
    right: 14px;
    top: 10px;
    cursor: pointer;
    z-index: 9999;
    /*クリック後、バツボタンになる*/
  }
  .header .burger::before {
    content: "";
    width: 20px;
    height: 2px;
    display: inline-block;
    background: #fff;
    position: absolute;
    top: calc(50% - 3.5px);
    left: 50%;
    transform: translate(-50%, -50%);
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: ease-out;
  }
  .header .burger::after {
    content: "";
    width: 20px;
    height: 2px;
    display: inline-block;
    background: #fff;
    position: absolute;
    top: calc(50% + 3.5px);
    left: 50%;
    transform: translate(-50%, -50%);
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: ease-out;
  }
  .header .burger.is-open {
    background: #fff;
  }
  .header .burger.is-open::before {
    background: #ffce08;
    top: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
  }
  .header .burger.is-open::after {
    background: #ffce08;
    top: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}
/*=================================
footer
=================================*/
.footer {
  background: #7d9dd7;
}
.footer__inner {
  max-width: 1380px;
  margin: 0 auto;
}
.footer .fNavList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 22px 3%;
}
.footer .fNavList__item {
  display: inline-block;
  position: relative;
}
.footer .fNavList__item:not(:first-child) {
  margin: 0 0 0 56px;
}
@media screen and (min-width: 767px) and (max-width: 1480px) {
  .footer .fNavList__item:not(:first-child) {
    margin: 0 0 0 3.7837837838vw;
  }
}
.footer .fNavList__item::after {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  background: #4673c6;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  /*アニメーション*/
  transition: transform 0.3s;
  transform: scale(0, 1);
  transform-origin: right top;
}
@media screen and (min-width: 767px) {
  .footer .fNavList__item:hover::after {
    transform: scale(1, 1);
    transform-origin: left top;
  }
}
.footer .fNavList__item a {
  font-size: clamp(1.1rem, 0.8783783784vw, 1.3rem);
  font-weight: 500;
  color: #fff;
  padding: 0 5px;
  display: block;
  text-decoration: none;
  position: relative;
  z-index: 2;
}
.footer .copy {
  font-family: "Montserrat", sans-serif;
  font-size: 1.1rem;
  letter-spacing: 0.16em;
  color: #fff;
  background: #4673c6;
  padding: 9px 3%;
  display: block;
  text-align: center;
}

@media screen and (min-width: 0) and (max-width: 767px) {
  /*=================================
  footer
  =================================*/
  .footer .fNavList {
    padding: 15px 0 10px;
  }
  .footer .fNavList__item {
    margin: 0 3.8461538462% 5px;
  }
  .footer .fNavList__item:not(:first-child) {
    margin: 0 3.8461538462% 5px;
  }
  .footer .fNavList__item::after {
    content: none;
  }
  .footer .fNavList__item a {
    font-size: 1.2rem;
    padding: 0;
  }
  .footer .copy {
    font-size: 1rem;
    padding: 10px 3.8461538462%;
  }
}