@use "../global" as *;
/* =================================
  header
================================= */
.home {
  .header {
    background: transparent;
    padding: 33px 3%;
    position: absolute;
    z-index: 10;
    .gNav {
      &List {
        &__item {
          &::after {
            background: $color-yellow;
          }
        }
      }
    }
  }
}
.header {
  width: 100%;
  padding: 10px 3%;
  background: #7d9dd7;
  &__inner {
    display: flex;
    align-items: center;
  }
  &__logo {
    width: clamp(184px, vw(284), 284px);
    .logo {
      display: inline-block;
      vertical-align: middle;
      a {
        display: block;
        @include mq(pc) {
          &:hover {
            opacity: .8;
          }
        }
      }
      img {
        vertical-align: baseline;
      }
    }
  }
  .gNav {
    margin-left: auto;
    &List {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      &__item {
        display: inline-block;
        position: relative;
        &:not(:first-child) {
          margin: 0 0 0 35px;
          @include mq(tab) {
            margin: 0 0 0 vw(35);
          }
        }
        &::after {
          content: "";
          width: 100%;
          height: 100%;
          display: block;
          background: $color-theme;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 0;
          /*アニメーション*/
          transition: transform .3s;
          transform: scale(0, 1);
          transform-origin: right top;
        }
        @include mq(pc) {
          &:hover {
            &::after {
              transform: scale(1, 1);
              transform-origin: left top;
            }
          }
        }
        a {
          @include font-montserrat;
          font-size: clamp(1.2rem, vw(15), 1.5rem);
          font-weight: 600;
          letter-spacing: .14em;
          color: #fff;
          padding: 0 5px;
          text-decoration: none;
          display: block;
          position: relative;
          z-index: 2;
        }
        &.current-menu-item {
          a {
            &::after {
              transform: scale(1, 1);
              transform-origin: left top;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 767px) {
  .gNavList {
    &.fixedNav {
      padding: 12px 3%;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      background: rgba(255, 206, 8, .8);
      box-shadow: 0 5px 25px rgba(34, 34, 34, .05);
      z-index: 999;
      animation: header-show .5s ease-in;
      .gNavList__item {
        &::after {
          background: #fff;
        }
        a {
          &:hover {
            color: $color-yellow;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  /* =================================
    header
  ================================= */
  .home {
    .header {
      padding: 0;
      top: 0;
      left: 0;
      z-index: 9999;
      .gNav {
        &List {
          &__item {
            &::after {
              background: transparent;
            }
          }
        }
      }
    }
  }
  .header {
    padding: 0;
    &__inner {
      display: block;
    }
    &__logo {
      width: 100%;
      min-height: 60px;
      padding: 13px 0 13px per(13);
      .logo {
        width: vw(124,$sp-width);
        line-height: 1;
      }
    }
    .gNav {
      overflow-y: scroll;
      max-height: 90vh;
      width: 100%;
      margin: auto;
      padding: 70px per(15);
      position: fixed;
      top: 0;
      left: 0;
      z-index: 9999;
      transform: scale(.9);
      opacity: 0;
      visibility: hidden;
      transition: opacity .5s ease-in-out, transform .3s ease-out,visibility .2s ease-out;
      &List {
        display: block;
        &__item {
          display: block;
          &:not(:first-child) {
            margin: per(15) 0 0;
          }
          &::after {
            content: "";
            width: 10px;
            height: 10px;
            display: inline-block;
            background: transparent;
            top: 50%;
            left: 0;
            transform: scale(1, 1) translateY(-50%);
            border-width: 0 6px 9px 6px;
            border-style: solid;
            border-color: transparent transparent #fff transparent;
          }
          a {
            font-size: 1.8rem;
            color: #fff;
            padding: 10px 5px 10px per(25);
            text-decoration: none;
            display: block;
            position: relative;
            z-index: 2;
          }
        }
      }
      &.show {
        transform: scale(1);
        visibility: visible;
        opacity: 1;
      }
      &.hide {
        transform: scale(.9);
        opacity: 0;
        visibility: hidden;
      }
    }
    .headerNav__cover {
      content: "";
      width: 100%;
      height: 100vh;
      display: block;
      background: $color-yellow;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 10;
      /*アニメーション*/
      transition: transform .3s;
      transform: scale(0, 1);
      transform-origin: right top;
      &.show {
        transform: scale(1, 1);
        transform-origin: left top;
      }
      &.hide {
        transform: scale(0, 1);
        transform-origin: right top;
      }
    }

    /*バーガーボタン設定*/
    .burger {
      width: 40px;
      height: 40px;
      background: $color-yellow;
      margin: auto;
      border-radius: 50px;
      position: fixed;
      right: 14px;
      top: 10px;
      cursor: pointer;
      z-index: 9999;
      &::before {
        content: "";
        width: 20px;
        height: 2px;
        display: inline-block;
        background: #fff;
        position: absolute;
        top: calc(50% - 3.5px);
        left: 50%;
        transform: translate(-50%,-50%);
        @include animation-base;
      }
      &::after {
        content: "";
        width: 20px;
        height: 2px;
        display: inline-block;
        background: #fff;
        position: absolute;
        top: calc(50% + 3.5px);
        left: 50%;
        transform: translate(-50%,-50%);
        @include animation-base;
      }
      /*クリック後、バツボタンになる*/
      &.is-open {
        background: #fff;
        &::before {
          background: $color-yellow;
          top: 50%;
          transform: translate(-50%,-50%) rotate(45deg);
        }
        &::after {
          background: $color-yellow;
          top: 50%;
          transform: translate(-50%,-50%) rotate(-45deg);
        }
      }
    }
  }
}