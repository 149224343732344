@use "../../global" as *;
/*=================================
  お問い合わせ
=================================*/
.formBox {
  @for $i from 1 through 6 {
    h#{$i}{
      margin-top: 0!important;
      margin-bottom: 80px!important;
    }
  }
  .step {
    display: inline-block;
    counter-reset: stepNum;
    margin-bottom: 55px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    &::before {
      content: "";
      width: calc(100% - 5.5rem);
      height: 1px;
      display: inline-block;
      background: #cbd5e8;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
    &__item {
      font-size: 1.8rem;
      font-weight: bold;
      text-align: center;
      padding-top: 26px;
      display: inline-block;
      position: relative;
      &::before {
        counter-increment: stepNum;
        content: "STEP0"counter(stepNum);
        display: block;
        font-size: 1.6rem;
        font-weight: 500;
        line-height: 1.625;
        color: $color-theme;
        margin-bottom: 10px;
      }
      &::after {
        content: "";
        width: 15px;
        height: 15px;
        display: inline-block;
        border-radius: 50px;
        background: #cbd5e8;
        position: absolute;
        top: -8px;
        left: 50%;
        transform: translateX(-50%);
      }
      &.active {
        &::after {
          background: $color-theme;
        }
      }
      & + .step__item {
        margin-left: 59px;
      }
    }
  }
  .btnWrap {
    margin-top: 40px;
  }
  .btn--form {
    display: block;
    text-align: center;
    &:not(.back) {
      input {
        font-size: 2rem;
        font-weight: bold;
        color: #fff;
        text-decoration: none;
        padding: 30px 68px 28px;
        border-radius: 5px;
        background: $color-theme;
        box-shadow: 0px 5px 0 0px #2a4983;
        cursor: pointer;
      }
      @include mq(pc) {
        &:hover {
          input {
            box-shadow: none!important;
            transform: translateY(5px);
          }
        }
      }
    }
    &.back {
      input {
        font-size: 1.4rem;
        color: #999;
        cursor: pointer;
      }
    }
  }
}
/* =================================
  お問い合わせフォーム
================================= */
.text--security {
  font-size: 1.6rem;
  margin-bottom: 30px;
  text-align: center;
}
.contactForm {
  table {
    width: 100%;
  }
  th {
    font-size: 1.6rem;
    padding: 15px 65px 15px 20px;
    text-align: left;
    width: 240px;
    position: relative;
    .sub {
      font-size: 1.4rem;
      display: block;
    }
    span:not(.sub) {
      font-size: 1.2rem;
      font-weight: normal;
      letter-spacing: 0.04em;
      line-height: 1;
      color: #fff;
      background: #cbd5e8;
      padding: 5px 12px;
      position: absolute;
      top: 20px;
      right: 10px;
    }
    .required {
      background: $color-red!important;
    }
  }
  td {
    font-size: 1.6rem;
    padding: 17px 15px 17px 20px;
    width: calc(100% - 240px);
    text-align: left;
    p {
      line-height: 1.65;
    }
    * {
      font-size: 1.6rem;
    }
    input,textarea,select {
      background: #fff;
      border: 2px solid #cbd5e8;
      width: 100%;
      padding: 15px;
      box-sizing: border-box;
    }
    textarea {
      min-height : 170px;
    }
    .error {
      font-size: 1.4rem;
      color: $color-red;
      margin: 10px 0 0;
      display: inline-block;
    }
  }
  .confirmingTxt {
    text-align: center;
    margin: 40px 0;
    p {
      font-size: 1.6rem;
    }
    .checkText {
      font-size: 1.8rem;
      margin-top: 40px;
      display: inline-block;
      input {
        margin-right: 10px;
      }
    }
    input {
      -webkit-appearance: checkbox;
    }
    a {
      font-weight: bold;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
.consultForm {
  &__item {
    display: flex;
    align-items: center;
    background: $color-theme-light;
    padding: 38px 20px;
    border: 1px solid #cbd5e8;
    & + .consultForm__item {
      margin-top: 20px;
    }
  }
  &__ttl {
    font-size: 1.6rem;
    font-weight: bold;
    color: $color-theme;
    width: 230px;
    padding-right: 55px;
    position: relative;
    .sub {
      font-size: 1.4rem;
      display: block;
    }
    span:not(.sub) {
      font-size: 1.2rem;
      font-weight: normal;
      letter-spacing: 0.04em;
      line-height: 1;
      color: #fff;
      background: #cbd5e8;
      padding: 5px 12px;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
    .required {
      background: $color-red!important;
    }
  }
  &__list {
    font-size: 1.6rem;
    width: calc(93% - 230px);
    padding-left: 27px;
    p {
      line-height: 1.65;
    }
    * {
      font-size: 1.6rem;
    }
    input:not([type="radio"]):not([type="checkbox"]),textarea,select {
      background: #fff;
      border: 2px solid #cbd5e8;
      padding: 10px;
    }
    input:not([type="radio"]):not([type="checkbox"]):not([class="widthNormal"]),textarea,select {
      width: 100%;
      box-sizing: border-box;
    }
    .mwform-radio-field {
      display: inline-block;
      margin-top: 1rem;
      margin-bottom: 1rem;
      margin-right: 3rem;
      label {
        display: inline-block;
      }
    }
    input[type="radio"] + span {
      padding-left: 2.8rem;
      display: inline-block;
      position: relative;
    }
    input[type="radio"] + span::before {
      content: "";
      width: 20px;
      height: 20px;
      box-sizing: border-box;
      margin: auto;
      border-radius: 50%;
      border: solid 2px #cbd5e8;
      background-color: #fff;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
    }
    input[type="radio"]:checked + span::after {
      content: "";
      width: 12px;
      height: 12px;
      border-radius: 50%;
      margin: auto 0;
      background-color: $color-theme;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 4px;
    }
    .mwform-checkbox-field {
      display: inline-block;
      margin-top: 1rem;
      margin-bottom: 1rem;
      margin-right: 3rem;
      label {
        display: inline-block;
      }
    }
    input[type="checkbox"] + span {
      padding-left: 2.8rem;
      display: inline-block;
      position: relative;
    }
    input[type="checkbox"] + span::before {
      content: "";
      width: 20px;
      height: 20px;
      box-sizing: border-box;
      margin: auto;
      border-radius: 4px;
      border: solid 2px #cbd5e8;
      background-color: #fff;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
    }
    input[type="checkbox"]:checked + span::after {
      content: "";
      width: 11px;
      height: 7px;
      display: inline-block;
      background: url(../img/common/icon/icon_check.png) no-repeat center center;
      background-size: contain;
      position: absolute;
      top: 10px;
      left: 5px;
    }
    textarea {
      min-height : 170px;
    }
    .error {
      font-size: 1.4rem;
      color: $color-red;
      margin: 10px 0 0;
      display: block;
    }
    .yoyaku {
      display: flex;
      align-items: center;
      .rank {
        font-weight: bold;
        color: $color-theme;
        width: 85px;
      }
      .yoyakuBox {
        width: calc(100% - 85px);
        display: flex;
      }
      .yoyakudate {
        width: calc((100% - 15px) / 2)!important;
      }
      .yoyakujikan {
        width: calc((100% - 15px) / 2)!important;
        margin-left: 15px;
      }
      & + .yoyaku {
        margin-top: 15px;
      }
    }
  }
  .confirmingTxt {
    text-align: center;
    margin: 37px 0;
    p {
      font-size: 1.4rem;
    }
    input {
      -webkit-appearance: checkbox;
    }
    a {
      text-decoration: underline;
      &:hover {
        color: $color-theme;
      }
    }
  }
  & + .consultForm {
    border-top: 1px dashed #cbd5e8;
    padding-top: 20px;
    margin-top: 20px;
  }
}
.completedForm {
  .completedMessage {
    font-size: 1.8rem;
    font-weight: bold;
    line-height: 1;
    letter-spacing: 0.06em;
    display: inline-block;
    margin: 24px 0 0;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    &::after {
      content: "";
      width: 100%;
      height: 3px;
      background: $color-theme;
      display: inline-block;
    }
  }
  p {
    font-size: 1.5rem;
    text-align: center;
    margin: 40px 0 0;
  }
  table {
    width: initial;
    margin: 40px auto 45px;
  }
  th,td {
    width: auto;
  }
  th {
    font-size: 1.6rem;
    font-weight: bold;
    vertical-align: middle;
    padding: 15px 30px;
  }
  td {
    vertical-align: middle;
    padding: 15px 30px;
    .tel {
      font-size: 3.4rem;
      font-weight: bold;
      color: $color-theme;
      line-height: 1;
      text-decoration: none;
      margin: 0;
      @include mq(pc) {
        pointer-events: none;
      }
    }
    .receptionTime {
      display: flex;
      margin: 10px 0 0;
      dt {
        font-size: 1.3rem;
        font-weight: bold;
        color: $color-theme;
        width: 5.5em;
      }
      dd {
        font-size: 1.3rem;
        width: calc(100% - 5.5em);
      }
    }
  }
}
@media screen and (max-width: $display-width-s) {
  /*=================================
    お問い合わせ
  =================================*/
  .formBox {
    @for $i from 1 through 6 {
      h#{$i}{
        margin-bottom: 40px!important;
      }
    }
    .infoBox {
      display: block;
      margin-top: 0;
      overflow: hidden;
      .thumb {
        width: 30%;
        margin-right: 0;
        margin-bottom: 15px;
        float: left;
      }
      .contact {
        width: 100%;
        .infoBoxTtl {
          font-size: 1.5rem;
          line-height: 1.5;
          width: calc(100% - (30% + 15px));
          margin-left: auto;
        }
        .address {
          .info--tel {
            margin-top: 0;
            .tel {
              width: calc(100% - (30% + 15px));
              margin-left: auto;
              margin-top: 15px;
              .num {
                font-size: 1.8rem;
                color: #fff;
                background: $color-theme;
                display: block;
                padding: 10px 10px;
                border-radius: 3px;
                text-align: center;
                &::before {
                  width: 18px;
                  height: 18px;
                  display: inline-block;
                  margin: 0 7px -3px 0;
                }
              }
            }
            .receptionTime {
              width: 100%;
              font-size: 1.4rem;
              margin-top: 15px;
            }
          }
        }
      }
    }
    .step {
      margin-bottom: 40px;
      &::before {
        width: calc(100% - 5rem);
      }
      &__item {
        font-size: 1.6rem;
        padding-top: 20px;
        &::before {
          font-size: 1.4rem;
          margin-bottom: 5px;
        }
        &::after {
          width: 13px;
          height: 13px;
          top: -6px;
        }
        &.active {
          &::after {
            background: $color-theme;
          }
        }
        & + .step__item {
          margin-left: vw(59,$sp-width);
        }
      }
    }
    .btnWrap {
      margin-top: 35px;
    }
    .btn--form {
      &:not(.back) {
        input {
          font-size: 1.6rem;
          padding: 15px per(14) 13px;
          border-radius: 4px;
          box-shadow: 0px 4px 0 0px #2a4983;
        }
      }
    }
  }
  /* =================================
    お問い合わせフォーム
  ================================= */
  .text--security {
    font-size: 1.4rem;
  }
  .contactForm {
    table {
      display: table;
      white-space: initial;
    }
    th {
      font-size: 1.5rem;
      padding: 15px per(14);
      width: 100%;
      display: block;
      span:not(.sub) {
        font-size: 1.1rem;
        letter-spacing: 0.4em;
        padding: 4px 4px 4px 8px;
        margin-left: 1.5rem;
        position: static;
        transform: translateY(0);
      }
    }
    td {
      padding: 15px per(14);
      width: 100%;
      display: block;
      p {
        line-height: 1.5;
      }
      input,textarea,select {
        padding: 12px;
      }
      .error {
        font-size: 1.3rem;
      }
    }
    .confirmingTxt {
      margin: 30px 0;
      p {
        font-size: 1.4rem;
      }
      .checkText {
        font-size: 1.6rem;
        margin-top: 10px;
      }
    }
  }
  .consultForm {
    &__item {
      display: block;
      padding: 10px per(14) 20px;
    }
    &__ttl {
      font-size: 1.5rem;
      padding: 15px 0;
      width: 100%;
      display: block;
      .sub {
        font-size: 1.4rem;
        display: inline-block;
      }
      span:not(.sub) {
        font-size: 1.1rem;
        letter-spacing: 0.4em;
        padding: 4px 4px 4px 8px;
        margin-left: 1.5rem;
        position: static;
        transform: translateY(0);
      }
    }
    &__list {
      width: 100%;
      padding-left: 0;
      p {
        line-height: 1.5;
      }
      .mwform-radio-field {
        margin-right: .5rem;
      }
      .mwform-checkbox-field {
        margin-right: .5rem;
      }
      input[type="checkbox"]:checked + span::after {
        width: 15px;
        height: 10px;
        top: 6px;
        left: 3px;
      }
      .error {
        font-size: 1.3rem;
      }
      .yoyaku {
        flex-wrap: wrap;
        .rank {
          width: 100%;
          margin-bottom: 10px;
        }
        .yoyakuBox {
          width: 100%;
          display: block;
        }
        .yoyakudate {
          width: 100%!important;
        }
        .yoyakujikan {
          width: 100%!important;
          margin: 10px 0 0;
        }
        & + .yoyaku {
          margin-top: 20px;
        }
      }
    }
    .confirmingTxt {
      text-align: center;
      margin: 37px 0;
      p {
        font-size: 1.4rem;
      }
    }
  }
  .completedForm {
    p {
      margin: 30px 0 0;
    }
    table {
      display: table;
      white-space: initial;
      margin: 40px auto 45px;
    }
    th,td {
      width: auto;
    }
    th {
      font-size: 1.4rem;
      padding: 15px per(14);
    }
    td {
      padding: 15px per(14);
      .tel {
        font-size: 3rem;
      }
      .receptionTime {
        text-align: left;
      }
    }
  }
}