@use "variables" as *;
@use "breakpoint" as *;
@use "font-family" as *;
@mixin singleStyle-base {
  h1,h2,h3,h4,h5,h6 {
    clear: both;
  }
  p:not([class]) {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 2;
    & + p {
      margin-top: 20px;
    }
  }
  h1:not([class]),h1.wp-block-heading {
    font-size: 4.2rem;
    font-weight: 700;
    line-height: 1.33333;
    margin-top: 100px;
    margin-bottom: 40px;
    padding-left: 25px;
    position: relative;
    &::before {
      content: "";
      width: 12px;
      height: 12px;
      display: inline-block;
      vertical-align: middle;
      border-radius: 50px;
      background: $color-theme;
      position: absolute;
      top: 25px;
      left: 0;
    }
  }
  h2:not([class]),h2.wp-block-heading {
    font-size: 2.4rem;
    font-weight: 700;
    color: #fff;
    line-height: 1.66666;
    margin-top: 100px;
    margin-bottom: 40px;
    padding: 28px 30px;
    background: $color-theme;
    @include mq(tab) {
      padding: vw(28) vw(30);
    }
  }
  h3:not([class]),h3.wp-block-heading {
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.5;
    margin-top: 60px;
    margin-bottom: 30px;
    padding-bottom: 20px;
    border-bottom: 3px solid #cbd5e8;
    position: relative;
    &::after {
      content: "";
      width: 9em;
      height: 3px;
      display: inline-block;
      background: $color-theme;
      position: absolute;
      left: 0;
      bottom: -3px;
    }
  }
  h4:not([class]),h4.wp-block-heading {
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 1.5;
    margin-top: 60px;
    margin-bottom: 20px;
    padding-left: 29px;
    position: relative;
    &::before {
      content: "";
      width: 8px;
      height: 8px;
      display: inline-block;
      vertical-align: middle;
      border-radius: 50px;
      background: $color-theme;
      position: absolute;
      top: 10px;
      left: 8px;
    }
  }
  h5:not([class]),h5.wp-block-heading {
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.44444;
    color: $color-theme;
    margin-top: 40px;
    margin-bottom: 20px;
    padding: 10px 15px;
    border-left: 4px solid $color-theme;
    background: $color-gray;
  }
  h6:not([class]),h6.wp-block-heading {
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 1.77777;
    color: $color-theme;
    margin-top: 40px;
    margin-bottom: 20px;
    padding-left: 20px;
    position: relative;
    &::before {
      content: "";
      width: 10px;
      height: 10px;
      display: inline-block;
      background: $color-theme;
      position: absolute;
      top: 12px;
      left: 0;
    }
  }
  blockquote {
    position: relative;
    padding: 50px 20px 20px;
    margin: 50px 0 30px;
    box-sizing: border-box;
    font-style: italic;
    color: #464646;
    border: 2px solid $color-theme-light;
    &::before {
      content: "“";
      font-size: 5rem;
      font-weight: 700;
      color: $color-theme;
      display: inline-block;
      font-style: normal;
      width: 50px;
      height: 50px;
      line-height: 1.3;
      text-align: center;
      background: $color-theme-light;
      border-radius: 50px;
      position: absolute;
      top: -25px;
      left: 15px;
    }
    @for $i from 1 through 6 {
      h#{$i}{
        margin: 0 0 20px!important;
        font-size: 1.6rem;
        line-height: 1.75;
        letter-spacing: .06em;
        position: relative;
      }
    }
    p {
      font-style: normal;
    }
    cite {
      display: block;
      text-align: right;
      line-height: 1;
      color: #888888;
      font-size: 0.9em;
      margin: 10px 0 0;
    }
  }
  .btnWrap {
    clear: both;
  }
  img:not([class]),&.wp-block-heading {
    display: block;
    margin: 15px auto 20px;
  }
  img {
    &.alignleft {
      float: left;
      margin: 0 30px 30px 0;
    }
    &.alignright {
      float: right;
      margin: 0 0 30px 30px;
    }
  }
  ul:not([class]) {
    margin: 30px 0;
    padding: 30px;
    background: #FFF;
    border: 2px solid #cbd5e8;
    clear: both;
    @include mq(tab) {
      padding: vw(30);
    }
    li {
      font-size: 1.6rem;
      font-weight: 700;
      line-height: 2;
      padding-left: 18px;
      position: relative;
      &::before {
        content: "";
        width: 8px;
        height: 8px;
        border-radius: 50px;
        background: $color-theme;
        display: inline-block;
        position: absolute;
        top: 13px;
        left: 0;
      }
      & + li {
        margin-top: 10px;
      }
    }
    @for $i from 1 through 6 {
      h#{$i}::before{
        content: none;
      }
    }
  }
  ol:not([class]) {
    margin: 30px 0;
    padding: 30px;
    background: $color-theme-light;
    counter-reset: listNum;
    clear: both;
    li {
      font-size: 1.6rem;
      font-weight: 700;
      line-height: 2;
      padding-left: 38px;
      position: relative;
      &::before {
        counter-increment: listNum;
        content: counter(listNum);
        font-size: 1.6rem;
        font-weight: 700;
        color: $color-theme;
        width: 28px;
        text-align: center;
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
      }
      & + li {
        margin-top: 10px;
      }
    }
    @for $i from 1 through 6 {
      h#{$i}::before{
        content: none;
      }
    }
  }
  @include mq(sp) {
    p:not([class]) {
      font-size: 1.4rem;
      line-height: 1.85714;
      & + p {
        margin-top: 10px;
      }
    }
    h1:not([class]),h1.wp-block-heading {
      font-size: 2.4rem;
      margin-top: 54px;
      margin-bottom: 20px;
      padding-left: 15px;
      &::before {
        width: 8px;
        height: 8px;
        top: 13px;
      }
    }
    h2:not([class]),h2.wp-block-heading {
      font-size: 1.8rem;
      line-height: 1.38888;
      margin-top: 54px;
      margin-bottom: 20px;
      padding: 15px per(30);
      background: $color-theme;
    }
    h3:not([class]),h3.wp-block-heading {
      font-size: 1.6rem;
      line-height: 1.625;
      margin-top: 40px;
      margin-bottom: 20px;
      padding-bottom: 14px;
      &::after {
        width: 6.5em;
      }
    }
    h4:not([class]),h4.wp-block-heading {
      font-size: 1.6rem;
      line-height: 1.625;
      margin-top: 40px;
      padding-left: 20px;
      &::before {
        top: 10px;
        left: 5px;
      }
    }
    h5:not([class]),h5.wp-block-heading {
      font-size: 1.5rem;
      line-height: 1.73333;
      padding: 10px 12px;
    }
    h6:not([class]),h6.wp-block-heading {
      font-size: 1.5rem;
      line-height: 1.73333;
      padding-left: 18px;
      &::before {
        width: 8px;
        height: 8px;
        top: 9px;
      }
    }
    blockquote {
      padding: 40px per(15) 20px;
      margin: 40px 0 30px;
      box-sizing: border-box;
      font-style: italic;
      color: #464646;
      border: 2px solid $color-theme-light;
      &::before {
        font-size: 4rem;
        width: 45px;
        height: 45px;
        line-height: 1.5;
      }
      @for $i from 1 through 6 {
        h#{$i}{
          font-size: 1.5rem;
        }
      }
    }
    img {
      &.alignleft {
        float: none;
        display: block;
        margin: 15px auto 20px;
      }
      &.alignright {
        float: none;
        display: block;
        margin: 15px auto 20px;
      }
    }
    ul:not([class]) {
      margin: 20px 0;
      padding: 25px per(25);
      li {
        font-size: 1.4rem;
        line-height: 1.71428;
        padding-left: 14px;
        &::before {
          width: 6px;
          height: 6px;
          top: 8px;
        }
      }
    }
    ol:not([class]) {
      margin: 20px 0;
      padding: 25px per(15);
      li {
        font-size: 1.4rem;
        line-height: 1.71428;
        &::before {
          font-size: 1.4rem;
        }
      }
    }
  }
  iframe {
    width: 100%;
  }
}
@mixin singleStyle-parts {
  /*---------------------
    メインビジュアル
  ---------------------*/
  .articleMv {
    margin: 40px auto;
    text-align: center;
    overflow: hidden;
    img {
      border-radius: 15px;
    }
  }
  .date {
    display: flex;
    justify-content: flex-end;
    * {
      font-size: 1.4rem!important;
      color: #7c7c7c;
      & + * {
        margin: 0 0 0 1rem!important;
      }
    }
  }
  /*---------------------
    目次
  ---------------------*/
  .tocBox {
    margin: 40px auto 100px;
    &__inner {
      background: #fff;
      border: 1px solid #DFDFDF;
      padding: 34px vw(56);
    }
    &__ttl {
      font-size: 2.9rem;
      font-weight: 700;
      line-height: 1;
      margin-bottom: 24px;
      margin-left: 32px;
      &::before {
        content: "";
        width: 8px;
        height: 8px;
        display: inline-block;
        background: $color-theme;
        border-radius: 50px;
        vertical-align: middle;
        margin: -5px 11px 0 0;
      }
    }
    .tocList {
      counter-reset: tocNum;
      &__item {
        position: relative;
        border-bottom: 1px dashed #D4D4D4;
        &::before {
          counter-increment: tocNum;
          content: counter(tocNum)".";
          font-size: 1.6rem;
          font-weight: 400;
          color: $color-link;
          display: inline-block;
          position: absolute;
          top: 1px;
          left: 32px;
        }
        a {
          font-size: 1.6rem;
          font-weight: 400;
          display: inline-block;
          padding: 0 0 10px 60px;
          @include mq(pc) {
            &:hover {
              text-decoration: underline;
              color: $color-theme;
            }
          }
        }
        & + .tocList__item {
          margin-top: 5px;
        }
        .tocList {
          counter-reset: tocChildNum;
          padding-left: 26px;
          &__item {
            &::before {
              counter-increment: tocChildNum;
              content: counter(tocNum) " - " counter(tocChildNum)".";
            }
            a {
              padding-left: 36px;
            }
            & + .tocList__item {
              margin-top: 5px;
            }
          }
        }
      }
    }
    .tocMore {
      font-size: 1.5rem;
      font-weight: 500;
      background: $color-yellow;
      text-align: center;
      margin-top: 20px;
      padding: 8px 20px;
      display: inline-block;
      text-align: center;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      cursor: pointer;
      @include mq(pc) {
        &:hover {
          color: #fff;
          background: $color-theme;
        }
      }
    }
    &:not(.open) {
      .tocBox__inner {
        > .tocList > .tocList__item:nth-child(n + 4) {
          display: none;
        }
      }
      .tocMore {
        &::after {
          transform: rotate(0deg);
        }
        @include mq(pc) {
          &:hover {
            &::after {
              transform: translateY(2px) rotate(90deg);
            }
          }
        }
      }
    }
  }
  @include mq(sp) {
    /*---------------------
      記事タイトル
    ---------------------*/
    .articleTtl {
      font-size: 2.2rem;
      line-height: 1.5;
      padding: 0 per(15);
    }
    /*---------------------
      メインビジュアル
    ---------------------*/
    .articleMv {
      margin: 20px auto;
      padding: 0 per(15);
    }
    .date {
      justify-content: flex-start;
      * {
        font-size: 1.1rem!important;
      }
    }
    /*---------------------
      目次
    ---------------------*/
    .tocBox {
      margin: 30px auto;
      padding: 0 per(15);
      &__inner {
        padding: 20px per(15);
      }
      &__ttl {
        font-size: 1.5rem;
        line-height: 1;
        margin-bottom: 20px;
      }
      .tocList {
        &__item {
          &::before {
            top: 0;
          }
        }
      }
      .tocMore {
        font-size: 1.4rem;
      }
    }
  }
}