@use "../../global" as *;

/*=================================
  button
=================================*/
.btnWrap {
  margin: 20px auto 30px;
  text-align: center;
  &.center {
    text-align: center;
  }
  &.right {
    text-align: right;
  }

  > * + * {
    margin-top: 9px;
  }
}
.btn--blue {
  font-size: 1.4rem;
  font-weight: 500;
  color: #fff;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  background: $color-theme;
  padding: 15px;
  position: relative;
  overflow: hidden;
  span {
    position: relative;
    z-index: 1;
  }
}
.btn--gray {
  font-size: 1.9rem;
  font-weight: 700;
  line-height: 1.05;
  color: #fff;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  background: #333333;
  box-shadow: 0 5px 0 #202427;
  display: block;
  padding: 28px 30px;
  position: relative;
  @include animation-base(all,0s);
  @include mq(tab) {
    padding: vw(33) vw(225);
  }
  &::after {
    content: "";
    width: 12px;
    height: 19px;
    display: inline-block;
    vertical-align: middle;
    background: url(../img/icon/icon_arrow_w.png) no-repeat center center;
    background-size: contain;
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
  }
}
.btn--blueShadow {
  font-size: 1.4rem;
  font-weight: 500;
  color: #fff;
  display: inline-block;
  text-align: center;
  background: $color-blue-dark;
  box-shadow: 0 3px 0 $color-theme;
  border-radius: 4px;
  padding: 23px 22px;
  text-decoration: none;
}
.btn--ghost {
  font-size: 1.5rem;
  font-weight: 500;
  color: #fff;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  padding: 15px 58px;
  border: 1px solid #fff;
  background: rgba(255,255,255,.3);
  position: relative;
  &::after {
    content: "";
    width: 9px;
    height: 15px;
    display: inline-block;
    vertical-align: middle;
    background: url(../img/icon/icon_arrow_w.png) no-repeat center center;
    background-size: contain;
    position: absolute;
    top: 50%;
    right: 24px;
    transform: translateY(-50%);
  }
}
.btn--border {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1;
  color: $color-theme;
  border: 3px solid $color-theme;
  background: #fff;
  text-decoration: none;
  text-align: center;
  display: block;
  margin: 0 auto;
  padding: 16px 1%;
  &::before {
    content: "";
    display: inline-block;
    width: 17px;
    height: 17px;
    background: url(../img/btn_arrow.png) no-repeat center center;
    background-size: contain;
    vertical-align: middle;
  }
}

@include mq(pc) {
  /*=================================
    button
  =================================*/
  .btnWrap {
    display: flex;
    justify-content: center;
    > * + * {
      margin: 0 20px;
    }
  }
  .btn--blue {
    font-size: 2.2rem;
    padding: 23px 37px;
    position: relative;
    overflow: hidden;
    &::after {
      content: "";
      width: 100%;
      height: 100%;
      display: block;
      background: $color-yellow;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      /*アニメーション*/
      transition: transform .6s cubic-bezier(0.8, 0, 0.2, 1) 0s;
      transform: scale(0, 1);
      transform-origin: right top;
    }
    &:hover {
      &::after {
        transform: scale(1, 1);
        transform-origin: left top;
      }
    }
  }
  .btn--gray {
    font-size: 2rem;
    padding: 33px 225px;
    &::after {
      width: 14px;
      height: 24px;
      right: 16px;
    }
    &:hover {
      box-shadow: none;
      transform: translateY(5px);
    }
  }
  .btn--blueShadow {
    font-size: 2.2rem;
    box-shadow: 0 5px 0 $color-theme;
    padding: 24px 30px;
    transition: none;
    &:hover {
      transform: translateY(5px);
      box-shadow: none;
    }
  }
  .btn--ghost {
    font-weight: 700;
    background: transparent;
    &:hover {
      background: rgba(255,255,255,.2);
    }
  }
  .btn--border {
    font-size: 2.2rem;
    border-width: 4px;
    padding: 20px 66px;
    margin: 0;
    display: inline-block;
    &::before {
      width: 24px;
      height: 24px;
      margin: 0 6px 3px 0;
    }
    &:hover {
      background: $color-blue;
    }
  }
}